import React, { useEffect, useState } from "react";
import FilterComponent from "./components/FilterComponent";
import "../../styles/playground.style.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchScoreCardData } from "../../redux/reducers/PlaygroundSlice";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import PercentIcon from "@mui/icons-material/Percent";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import TargetMarginSetting from "./components/TargetMarginSetting";
import PlanConfidence from "./components/PlanConfidence";
import HeadwindTailwind from "./components/HeadwindTailwind";

const PlaygroundMainPage = () => {
  const dispatch = useDispatch();
  const { scorecardData, scorecardStatus } = useSelector(
    (state) => state.PlaygroundSlice
  );

  const [activeTab, setActiveTab] = useState("targetMargin");

  useEffect(() => {
    let filters = {
      filters: {
        sector: [],
        market: [],
        category:[]
      },
    };
    dispatch(fetchScoreCardData(filters));
  }, [dispatch]);

  useEffect(() => {
    console.log("Scorecard Data from redux ::", scorecardData, scorecardStatus);
  }, [scorecardData, scorecardStatus]);

  const convertToMillion = (number) => {
    if (number) {
      return (
        (number / 100000).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
      );
    } else {
      return null;
    }
  };

  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      {" "}
      Playground
    </Typography>,
  ];

  return (
    <div className="container-fullpage">
      <div className="pg-link">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ margin: "2px 0", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div
        className="pg-link"
        style={{ margin: "2px 0", borderBottom: "3px solid #BFBFBF" }}
      />

      <div
        className="pg-link"
        style={{ color: '#000606', fontSize: '24px', fontWeight: '500' }}
      >
        Playground
      </div>

      <div className="pg-card-section">
        {/* {scorecardStatus === "loading" ? (
          <div
            style={{
              width: "100%",
              height: "130px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <> */}

        <div className="pg-card">
          <div className="pg-card-title" style={{ fontSize: 16, fontWeight: 700 }}>2024</div>
          <div className="pg-card-content">
            <div className="pg-card-bottom">
              <div>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  GM FCST
                </div>
                <div className="pg-card-values">
                  4.5%
                </div>
              </div>

              <div style={{ marginLeft: "25px" }}>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  Gap to Target
                </div>
                <div className="pg-card-values" style={{ color: '#FF0000' }}>
                  ${scorecardData?.target_gm}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "60px",
                width: "60px",
                borderRadius: "50%",
                background: "#3FBAD9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PercentIcon style={{ color: "#fff", fontSize: 30 }} />
            </div>
          </div>
          <div style={{ background: '#DEE7E9', borderRadius: '20px', width: '70%', display: 'flex', alignItems: 'center' }}>
            <CallMadeRoundedIcon style={{ background: '#BBD1D3', color: '#00656F', borderRadius: '20px', padding: '5px' }} />
            <div>

              <span style={{ fontWeight: 500, marginLeft: '10px' }}>
                Target
              </span>
              <span style={{ fontWeight: 700, marginLeft: '10px' }}>
                $150M
              </span>
            </div>
          </div>
        </div>
        <div className="pg-card">
          <div className="pg-card-title" style={{ fontSize: 16, fontWeight: 700 }}>2024</div>
          <div className="pg-card-content">
            <div className="pg-card-bottom">
              <div>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  GM FCST
                </div>
                <div className="pg-card-values">
                  4.5%
                </div>
              </div>

              <div style={{ marginLeft: "25px" }}>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  Gap to Target
                </div>
                <div className="pg-card-values" style={{ color: '#3AA806' }}>
                  ${scorecardData?.target_gm}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "60px",
                width: "60px",
                borderRadius: "50%",
                background: "#3FBAD9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PercentIcon style={{ color: "#fff", fontSize: 30 }} />
            </div>
          </div>
          <div style={{ background: '#DEE7E9', borderRadius: '20px', width: '70%', display: 'flex', alignItems: 'center' }}>
            <CallMadeRoundedIcon style={{ background: '#BBD1D3', color: '#00656F', borderRadius: '20px', padding: '5px' }} />
            <div>

              <span style={{ fontWeight: 500, marginLeft: '10px' }}>
                Target
              </span>
              <span style={{ fontWeight: 700, marginLeft: '10px' }}>
                $150M
              </span>
            </div>
          </div>
        </div>
        <div className="pg-card">
          <div className="pg-card-title" style={{ fontSize: 16, fontWeight: 700 }}>2024</div>
          <div className="pg-card-content">
            <div className="pg-card-bottom">
              <div>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  GM FCST
                </div>
                <div className="pg-card-values">
                  4.5%
                </div>
              </div>

              <div style={{ marginLeft: "25px" }}>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  Gap to Target
                </div>
                <div className="pg-card-values" style={{ color: '#FF0000' }}>
                  ${scorecardData?.target_gm}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "60px",
                width: "60px",
                borderRadius: "50%",
                background: "#3FBAD9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PercentIcon style={{ color: "#fff", fontSize: 30 }} />
            </div>
          </div>
          <div style={{ background: '#DEE7E9', borderRadius: '20px', width: '70%', display: 'flex', alignItems: 'center' }}>
            <CallMadeRoundedIcon style={{ background: '#BBD1D3', color: '#00656F', borderRadius: '20px', padding: '5px' }} />
            <div>

              <span style={{ fontWeight: 500, marginLeft: '10px' }}>
                Target
              </span>
              <span style={{ fontWeight: 700, marginLeft: '10px' }}>
                $150M
              </span>
            </div>
          </div>
        </div>
        <div className="pg-card">
          <div className="pg-card-title" style={{ fontSize: 16, fontWeight: 700 }}>2024</div>
          <div className="pg-card-content">
            <div className="pg-card-bottom">
              <div>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  GM FCST
                </div>
                <div className="pg-card-values">
                  4.5%
                </div>
              </div>

              <div style={{ marginLeft: "25px" }}>
                <div className="pg-card-title" style={{ fontSize: "14px" }}>
                  Gap to Target
                </div>
                <div className="pg-card-values" style={{ color: '#3AA806' }}>
                  ${scorecardData?.target_gm}
                </div>
              </div>
            </div>
            <div
              style={{
                height: "60px",
                width: "60px",
                borderRadius: "50%",
                background: "#3FBAD9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PercentIcon style={{ color: "#fff", fontSize: 30 }} />
            </div>
          </div>
          <div style={{ background: '#DEE7E9', borderRadius: '20px', width: '70%', display: 'flex', alignItems: 'center' }}>
            <CallMadeRoundedIcon style={{ background: '#BBD1D3', color: '#00656F', borderRadius: '20px', padding: '5px' }} />
            <div>

              <span style={{ fontWeight: 500, marginLeft: '10px' }}>
                Target
              </span>
              <span style={{ fontWeight: 700, marginLeft: '10px' }}>
                $150M
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="init-details-page" style={{ background: '#fff', borderRadius: 16 }}>



        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: '2px solid #ECECEC',
          }}
        >
          <button
            style={{
              fontSize: "16px",
              fontWeight: 600,
              border: "1px solid #ECECEC",
              background: "none",
              cursor: "pointer",
              padding: '20px',
              borderRadius: '16px 16px 0px 0px',
              backgroundColor: activeTab === "targetMargin" ? "#3FBAD926" : "#fff",
            }}
            onClick={() => setActiveTab("targetMargin")}
          >
            Target Margin Setting
          </button>
          <button
            style={{
              fontSize: "16px",
              fontWeight: 600,
              border: "1px solid #ECECEC",
              background: "none",
              cursor: "pointer",
              padding: '20px',
              borderRadius: '16px 16px 0px 0px',
              backgroundColor: activeTab === "headwindTailwind" ? "#3FBAD926" : "#fff",
            }}
            onClick={() => setActiveTab("headwindTailwind")}
          >
            Headwinds & Tailwinds
          </button>
          <button
            style={{
              fontSize: "16px",
              fontWeight: 600,
              border: "1px solid #ECECEC",
              background: "none",
              cursor: "pointer",
              padding: '20px',
              borderRadius: '16px 16px 0px 0px',
              backgroundColor: activeTab === "planConfidence" ? "#3FBAD926" : "#fff",
            }}
            onClick={() => setActiveTab("planConfidence")}
          >
            Plan Confidence
          </button>
        </div>

        {activeTab === "targetMargin" ?
          <TargetMarginSetting /> : activeTab === "headwindTailwind" ? <HeadwindTailwind/> : <PlanConfidence/>
        }

      </div>
    </div>
  );
};

export default PlaygroundMainPage;