import React, { useEffect, useState } from "react";
import FilterItem from "./components/FilterItem";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import CustomMultiSelect from "./components/CustomMultiSelect";
import MoreFilterComponent from "./components/MoreFilterComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDriver,
  changeIsAllDriver,
  changeIsAllLever,
  changeIsAllSector,
  changeLever,
  changeSector,
} from "../../redux/reducers/InitiativeSlice";
import "../../styles/filter.style.scss";
import axios from "axios";

const InititativeFilters = () => {
  const [sectorList, setSectorList] = useState([]);
  const [leverList, setLeverList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  // const [isAllSector, setIsAllSector] = useState(true);
  // const [isAllLever, setIsAllLever] = useState(true);
  // const [isAllDriver, setIsAllDriver] = useState(true);

  const dispatch = useDispatch();
  const {
    currentSector,
    currentCategorySector,
    currentLever,
    currentDriver,
    isAllSector,
    isAllLever,
    isAllDriver,
  } = useSelector((state) => state.InitiativeSlice);

  useEffect(() => {}, [
    dispatch,
    currentSector,
    currentCategorySector,
    currentLever,
    currentDriver,
    isAllSector,
    isAllLever,
    isAllDriver,
  ]);

  useEffect(() => {
    const fetchFiltersList = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters`
      );
      const data = await response.data.data;
      console.log("Filter Response Data :::", data);
      setSectorList(data.sector);
      setLeverList(data.lever);
      setDriverList(data.driver);
    };

    console.log("Sector length :::", currentSector, isAllSector);

    fetchFiltersList();
  }, []);

  useEffect(() => {
    let filters= {
        sector: isAllSector ? [] : currentSector,
        lever: isAllLever ? [] : currentLever,
    };

    const fetchUpdatedFilterList = async () => {
      if (isAllLever) {
        setLeverList([]);
        setDriverList([]);
      }
      if (!isAllLever) {
        setDriverList([]);
      }
      setDriverList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/new`,
        { filters }
      );

      const data = await response.data.data;
      console.log("Filtered Filter List :::", data);
      if (isAllLever) {
        setLeverList(data.lever);
        setDriverList(data.driver);
      }
      if (!isAllLever) {
        setDriverList(data.driver);
      }
    };

    fetchUpdatedFilterList();
  }, [isAllSector, currentSector, isAllLever, currentLever]);

  return (
    <div className="init-filter-area">
      <div className="init-filter-title">
        {/* <h4>Statistics</h4>
        <p>Take look at overall matrice</p> */}
      </div>
      <div className="init-filter-list">
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Sector"}
            options={sectorList}
            selectedOptions={currentSector}
            setSelectedOptions={changeSector}
            isAllSelected={isAllSector}
            setIsAllSelected={changeIsAllSector}
            icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Lever"}
            options={leverList}
            selectedOptions={currentLever}
            setSelectedOptions={changeLever}
            isAllSelected={isAllLever}
            setIsAllSelected={changeIsAllLever}
            icon={<ElevatorOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Driver"}
            options={driverList}
            selectedOptions={currentDriver}
            setSelectedOptions={changeDriver}
            isAllSelected={isAllDriver}
            setIsAllSelected={changeIsAllDriver}
            icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <MoreFilterComponent
            name={"More Filters"}
            // list={["All"]}
            // data={["All"]}
            // setData={setDriver}
            icon={<FilterListOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        {/* <div className="init-filter-item">
          <FilterItem
            name={"Order By"}
            list={["All"]}
            data={["All"]}
            // setData={setDriver}
            icon={<AssignmentOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div> */}
      </div>
    </div>
  );
};

export default InititativeFilters;
