import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useParams, Link } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { DataGrid } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import "../../styles/initdetails.style.scss";
import axios from "axios";
import CategoryAllocationChart from "./CategoryAllocationChart";

const tableStyles = {
  "& .MuiDataGrid-cell": {
    justifyContent: "center",
    // textAlign: 'center',
  },
  "& .MuiDataGrid-columnHeader": {
    justifyContent: "center",
    padding: 0,
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    background: "#fff",
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiDataGrid-container--top::after": {
    content: "none",
    display: "none",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    boxShadow: "0px 4px 16px 0px #3241FF29",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px 15px 0 0",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "none",
    background: "none",
    fontSize: "14px",
    fontWeight: 500,
    color: "#334D74",
  },
  '& .MuiDataGrid-columnHeader[data-field="Total"] .MuiDataGrid-columnHeaderTitleContainer':
    {
      backgroundColor: "#FFFFFF",
    },
  '& .MuiDataGrid-columnHeader[data-field="2024"] .MuiDataGrid-columnHeaderTitleContainer':
    {
      backgroundColor: "#3FBAD91A",
    },
  '& .MuiDataGrid-columnHeader[data-field="2025"] .MuiDataGrid-columnHeaderTitleContainer':
    {
      backgroundColor: "#E4D4C799",
    },
  '& .MuiDataGrid-columnHeader[data-field="2026"] .MuiDataGrid-columnHeaderTitleContainer':
    {
      backgroundColor: "#5DB1341A",
    },
};

const complexityList = ["Low", "Medium", "High"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const tagList = [
  "tag1",
  // "tag2",
  // "tag3",
  "Duplicate",
  "To review",
  "Ready to review",
];

const InitiativeDetails = () => {
  const { id, sector, driver, lever, year } = useParams();
  console.log("Params value :::", id, sector);
  const history = useHistory();
  const [financialOwner, setFinancialOwner] = useState("");
  const [isEditFinancialOwner, setIsEditFinancialOwner] = useState(false);
  const [secondOwner, setSecondOwner] = useState("");
  const [isEditSecondOwner, setIsEditSecondOwner] = useState(false);
  const [impactTab, setImpactTab] = useState("imm");
  const [selectedStatus, setSelectedStatus] = useState("NOT_STARTED");
  const [selectedComplexity, setSelectedComplexity] = useState("Medium");
  const [risks, setRisks] = useState("");
  const [comments, setComments] = useState("");

  const [impactRows, setImpactRows] = useState([]);
  const [impactRowsCopy, setImpactRowsCopy] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [confirmEditCell, setConfirmEditCell] = useState(false);
  const [openConfirmEditCell, setOpenConfirmEditCell] = useState(false);

  const [categoryAllocationData, setCategoryAllocationData] = useState([]);
  const [categoryAllocationDataCopy, setCategoryAllocationDataCopy] = useState(
    []
  );

  const [initiativeDetailsData, setInitiativeDetailsData] = useState([]);
  const [initiativeDetailsResp, setInitiativeDetailsResp] = useState([]);
  const [updatedImpactvalues, setUpdatedImpactValues] = useState({});
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  const [totalCatPercentage, setTotalCatPercentage] = useState("");
  const [currentEditCateogry, setCurrentCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [categoryAllocationLoading, setCategoryAllocationLoading] =
    useState(false);

  const [open, setOpen] = React.useState(false);
  // const [chartData, setChartData] = useState([]);
  const filterCategory = (data) => {
    return data.filter((category) => category.allocation > 0);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateTotal = (data) => {
    let total = data.reduce(
      (acc, curr) => parseFloat(acc) + parseFloat(curr.allocation),
      0
    );
    return total;
  };

  const handleUpdateCategoryAllocation = (value, category) => {
    let upadateValue = categoryAllocationData.map((data) =>
      data.category === category
        ? { ...data, allocation: value || 0 }
        : { ...data }
    );

    console.log("Updated Category Allocation :::", upadateValue);
    // let total = upadateValue.reduce(
    //   (acc, curr) => parseFloat(acc) + parseFloat(curr.allocation),
    //   0
    // );
    let total = calculateTotal(upadateValue);
    setTotalCatPercentage(total);
    console.log("Updated Category Allocation :::", upadateValue, total);
    setCategoryAllocationData(upadateValue);
  };

  const fetchCategoryAllocationDetails = async (postdata) => {
    setCategoryAllocationLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/initiative/category-allocation`,
        { ...postdata }
      );

      const data = await response.data.data;
      console.log("category allocation resp ::", data);
      setCategoryAllocationLoading(false);
      // let total = data.reduce(
      //   (acc, curr) => parseFloat(acc) + parseFloat(curr.allocation),
      //   0
      // );

      let total = calculateTotal(data);
      setTotalCatPercentage(total);
      setCategoryAllocationData(data);
      setCategoryAllocationDataCopy(data);
    } catch (error) {
      console.error("Error while fetching Category Allocation :::", error);
      setCategoryAllocationLoading(false);
    }
  };

  const cancelCategoryAllocation = () => {
    setCurrentCategory("");
    setCategoryAllocationData(categoryAllocationDataCopy);
    let total = calculateTotal(categoryAllocationDataCopy);
    setTotalCatPercentage(total);
    setCategoryError("");
  };

  const saveCategoryAllocation = async () => {
    // let total = calculateTotal(categoryAllocationData);
    setCurrentCategory("");
    if (totalCatPercentage > 100.0 || totalCatPercentage < 100.0) {
      // alert("Total percentage must be 100");
      setCategoryError("should be 100%");
    } else {
      // console.log("API CALL to Save");
      setCategoryAllocationLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/initiative/category/save`,
          categoryAllocationData
        );

        const data = await response.data;
        setCategoryAllocationDataCopy(categoryAllocationData);
        console.log("Resp after saving category allocation ::", data);
        setCurrentCategory("");
        setCategoryAllocationLoading(false);
        setCategoryError("");
      } catch (error) {
        console.error("Error while saving cateogry allocation :::", error);
        setCategoryAllocationLoading(false);
      }
    }
  };

  const fetchInitiativeDetails = async () => {
    setLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/initiative/get-details/${id}/${sector}/${driver}/${lever}/${year}`
    );

    console.log("init-details :::", await response.data);
    const resp = await response.data.data;
    setInitiativeDetailsData(resp);
    setFinancialOwner(resp.financial_owner);
    setSecondOwner(resp.delegate);
    setSelectedStatus(resp.status ? resp.status : "ON_TRACK");
    setSelectedComplexity(resp.complexity);
    setRisks(resp.risks);
    setComments(resp.comments);
    let postdata = {
      src_id: id,
      sector: resp.sector,
      driver: driver,
      lever: lever,
      cal_year: year,
    };
    fetchCategoryAllocationDetails(postdata);

    const filterPayload = Object.fromEntries(
      Object.entries(resp).filter(
        ([key]) =>
          !["original", "impact_gm", "created_date"].some((prefix) =>
            key.startsWith(prefix)
          )
      )
    );
    console.log("After Remove Original ::", filterPayload);
    setInitiativeDetailsResp(filterPayload);
    console.log(resp, "resp")
    let impactRowsData = [
      {
        id: 1,
        metric: "NSV",
        Total: `${
          resp["impact_nsv_2024"] +
          resp["impact_nsv_2025"] +
          resp["impact_nsv_2026"]
        }`,
        2024: resp["impact_nsv_2024"] === null ? 0 : resp["impact_nsv_2024"],
        2025: resp["impact_nsv_2025"],
        2026: resp["impact_nsv_2026"],
        editable: true,
        field: "nsv",
      },
      {
        id: 2,
        metric: "GP($)",
        Total: `${
          resp["impact_gp_2024"] +
          resp["impact_gp_2025"] +
          resp["impact_gp_2026"]
        }`,
        2024: resp["impact_gp_2024"],
        2025: resp["impact_gp_2025"],
        2026: resp["impact_gp_2026"],
        editable: true,
        field: "gp",
      },
      {
        id: 3,
        metric: "Inc. GM Sector(%)",
        Total: `${
          resp["inc_gm_sector_2024"] +
          resp["inc_gm_sector_2025"] +
          resp["inc_gm_sector_2026"]
        }`,
        2024: resp["inc_gm_sector_2024"],
        2025: resp["inc_gm_sector_2025"],
        2026: resp["inc_gm_sector_2026"],
        field: "gm sector",
        editable: false,
      },
      {
        id: 4,
        metric: "Inc. GM Total(%)",
        Total: `${
          resp["inc_gm_total_2024"] +
          resp["inc_gm_total_2025"] +
          resp["inc_gm_total_2026"]
        }`,
        2024: resp["inc_gm_total_2024"],
        2025: resp["inc_gm_total_2025"],
        2026: resp["inc_gm_total_2026"],
        field: "gm total",
        editable: false,
      },
    ];

    setImpactRows(impactRowsData);
    setImpactRowsCopy(impactRowsData);

    setOriginalRows([
      {
        id: 1,
        metric: "NSV",
        Total: `${
          resp["original_nsv_2024"] +
          resp["original_nsv_2025"] +
          resp["original_nsv_2026"]
        }`,
        2024: resp["original_nsv_2024"],
        2025: resp["original_nsv_2025"],
        2026: resp["original_nsv_2026"],
      },
      {
        id: 2,
        metric: "GP($)",
        Total: `${
          resp["original_gp_2024"] +
          resp["original_gp_2025"] +
          resp["original_gp_2026"]
        }`,
        2024: resp["original_gp_2024"],
        2025: resp["original_gp_2025"],
        2026: resp["original_gp_2026"],
      },
      {
        id: 3,
        metric: "Inc. GM Sector(%)",
        Total: `${
          resp["inc_gm_sector_2024"] +
          resp["inc_gm_sector_2025"] +
          resp["inc_gm_sector_2026"]
        }`,
        2024: resp["inc_gm_sector_2024"],
        2025: resp["inc_gm_sector_2025"],
        2026: resp["inc_gm_sector_2026"],
        field: "gm sector",
        editable: false,
      },
      {
        id: 4,
        metric: "In. GM Total(%)",
        Total: `${
          resp["inc_gm_total_2024"] +
          resp["inc_gm_total_2025"] +
          resp["inc_gm_total_2026"]
        }`,
        2024: resp["inc_gm_total_2024"],
        2025: resp["inc_gm_total_2025"],
        2026: resp["inc_gm_total_2026"],
        field: "gm total",
        editable: false,
      },
    ]);

    setLoading(false);
  };

  useEffect(() => {
    fetchInitiativeDetails();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const updateInitiativeDetails = async () => {
    handleClose();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/initiative/save`,
        {
          ...initiativeDetailsResp,
          financial_owner: financialOwner ? financialOwner : "",
          delegate: secondOwner ? secondOwner : "",
          complexity: selectedComplexity ? selectedComplexity : "",
          status: selectedStatus,
          risks: risks ? risks : "",
          comments: comments ? comments : "",
        }
      );
      const resp = await response.data;
      console.log("Update resp :::", resp);
      window.scrollTo(0, 0);
      fetchInitiativeDetails();
      // alert("Saved data successfully !");
    } catch (error) {
      console.error("Error while saving initiative details ::", error);
      alert("Something went wrong, Please try again!");
    }
  };

  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   href="/monitoring-and-reporting"
    // >
    //   Monitoring and Reporting
    // </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      {" "}
      Initiative Details
    </Typography>,
  ];

  const columns = [
    {
      field: "metric",
      headerName: "",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      align: "center",
      type: "number",
      renderCell: (params) => (
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {(params.value && params.row.field === "gm sector") ||
          params.row.field === "gm total"
            ? parseFloat(params.value).toFixed(2)
            : Intl.NumberFormat("de-DE", {
                style: "decimal",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(parseFloat(params.value))}
        </div>
      ),
    },
    {
      field: "2024",
      headerName: "2024",
      flex: 2,
      align: "center",
      type: "number",
      editable: impactTab === "imm",
      renderCell: (params) => (
        <div>
          {/* {console.log("2024 Key Params :::", params.row)} */}
          {impactTab === "imm" ? (
            <div>
             {params.value >= 0 && (
            <div style={{ marginLeft: 30 }}>
              {["gm sector", "gm total"].includes(params.row.field?.trim().toLowerCase()) ? (
                parseFloat(params.value).toFixed(2) // display without formatting
              ) : (
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(params.value)
              )}
                    {params?.row.editable && (
                      <span>
                        <IconButton
                          onClick={() =>
                            params.api.startCellEditMode({
                              id: params.id,
                              field: "2024",
                            })
                          }
                        >
                          <EditNoteIcon />
                        </IconButton>{" "}
                      </span>
                    )}
                  </div>
                )
                // ))
              }
            </div>
          ) : (["gm sector", "gm total"].includes(params.row.field?.trim().toLowerCase())) ? (
            parseFloat(params.value).toFixed(2)
          ) : (
            Intl.NumberFormat("de-DE", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(parseFloat(params.value))
          )}
        </div>
      ),
    },
    {
      field: "2025",
      headerName: "2025",
      flex: 2,
      align: "center",
      type: "number",
      editable: impactTab === "imm",
      renderCell: (params) => (
        <div>
          {/* {console.log("2024 Key Params :::", params.row)} */}
          {impactTab === "imm" ? (
            <div>
             {params.value >= 0 && (
            <div style={{ marginLeft: 30 }}>
              {["gm sector", "gm total"].includes(params.row.field?.trim().toLowerCase()) ? (
                parseFloat(params.value).toFixed(2) // display without formatting
              ) : (
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(params.value)
              )}
                    {params?.row.editable && (
                      <span>
                        <IconButton
                          onClick={() =>
                            params.api.startCellEditMode({
                              id: params.id,
                              field: "2025",
                            })
                          }
                        >
                          <EditNoteIcon />
                        </IconButton>{" "}
                      </span>
                    )}
                  </div>
                )
                // ))
              }
            </div>
          ) : (["gm sector", "gm total"].includes(params.row.field?.trim().toLowerCase())) ? (
            parseFloat(params.value).toFixed(2)
          ) : (
            Intl.NumberFormat("de-DE", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(parseFloat(params.value))
          )}
        </div>
      ),
    },
    {
      field: "2026",
      headerName: "2026",
      flex: 2,
      align: "center",
      type: "number",
      editable: impactTab === "imm",
      renderCell: (params) => (
        <div>
          {/* {console.log("2024 Key Params :::", params.row)} */}
          {impactTab === "imm" ? (
            <div>
             {params.value >= 0 && (
            <div style={{ marginLeft: 30 }}>
              {["gm sector", "gm total"].includes(params.row.field?.trim().toLowerCase()) ? (
                parseFloat(params.value).toFixed(2) // display without formatting
              ) : (
                Intl.NumberFormat("de-DE", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(params.value)
              )}
                    {params?.row.editable && (
                      <span>
                        <IconButton
                          onClick={() =>
                            params.api.startCellEditMode({
                              id: params.id,
                              field: "2026",
                            })
                          }
                        >
                          <EditNoteIcon />
                        </IconButton>{" "}
                      </span>
                    )}
                  </div>
                )
                // ))
              }
            </div>
          ) : (["gm sector", "gm total"].includes(params.row.field?.trim().toLowerCase())) ? (
            parseFloat(params.value).toFixed(2)
          ) : (
            Intl.NumberFormat("de-DE", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(parseFloat(params.value))
          )}
        </div>
      ),
    },
  
  ];

  const handleCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const compareObject = (obj1, obj2, commonFieldName) => {
    const result = {};
    if (
      obj1[commonFieldName] &&
      obj2[commonFieldName] &&
      obj1[commonFieldName] === obj2[commonFieldName]
    ) {
      result[commonFieldName] = obj1[commonFieldName];
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && key !== commonFieldName) {
        if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          // result[key] = obj2[key];
          result[`impact_${commonFieldName}_${key}`] = obj2[key];
        }
      }
    }
    return result;
  };

  const processRowUpdate = (newRow, oldRow) => {
    // console.log("OLD & NEW Row :::", newRow, oldRow)
    console.log("NEW Row :::", newRow);
    const updatedRows = impactRows.map((row) => {
      if (row.id === newRow.id) {
        const updated2024 =
          newRow?.["2024"] === null ? 0 : parseFloat(newRow?.["2024"]);
        const updated2025 =
          newRow?.["2025"] === null ? 0 : parseFloat(newRow?.["2025"]);
        const updated2026 =
          newRow?.["2026"] === null ? 0 : parseFloat(newRow?.["2026"]);
        let updateTotal = updated2024 + updated2025 + updated2026;
        let updateRow = { ...newRow, Total: updateTotal };
        newRow = updateRow;
        console.log("NEW UPDATED ROW ::", updateRow);
        return updateRow;
      } else {
        return row;
      }
    });
    // alert("Update")
    setImpactRows(updatedRows);
    // console.log(
    //   "difference key an value :::",
    //   compareObject(oldRow, newRow, newRow.field)
    // );

    const updatedObj = compareObject(oldRow, newRow, newRow.field);
    const editedImpactValueObj = {
      ...initiativeDetailsResp,
      ...updatedObj,
    };

    console.log("Updated Values :::", editedImpactValueObj);

    setInitiativeDetailsResp(editedImpactValueObj);
    return newRow;
  };

  const handleIsCellEditable = (params) => {
    // console.log("CELL PARAM :::", params)
    return params.colDef.editable && params.row.editable;
  };

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         height: "100vh",
  //         width: "100vw",
  //         background: "#fffff85",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <div className="container-fullpage" style={{ position: "relative" }}>
      {loading && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            background: "#ffffff90",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            left: 0,
            top: 0,
            zIndex: 99,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="init-details-page">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginTop: "10px", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }} />
        <h2 className="init-details-header">Initiative Details</h2>
        <div className="init-details-card">
          <div className="init-details-item">
            <div className="init-details-key">ID</div>
            <div className="init-details-value">{id}</div>
          </div>
          <div className="init-details-item">
            <div className="init-details-key">Name</div>
            <div className="init-details-value">
              {initiativeDetailsData.name}
            </div>
          </div>
          <div className="init-details-item">
            <div className="init-details-key">Sector</div>
            <div className="init-details-value">
              {initiativeDetailsData.sector}
            </div>
          </div>
          <div className="init-details-item">
            <div className="init-details-key">Lever</div>
            <div className="init-details-value">
              {initiativeDetailsData.lever}
            </div>
          </div>
          <div className="init-details-item">
            <div className="init-details-key">Driver</div>
            <div className="init-details-value">
              {initiativeDetailsData.driver}
            </div>
          </div>
          <div className="init-details-item">
            <div className="init-details-key">Owner</div>
            <div className="init-details-value">
              {initiativeDetailsData.owner}
            </div>
          </div>
          <div className="init-details-item">
            <div className="init-details-key">Second Owner</div>
            <div className="init-details-value">
              {initiativeDetailsData.delegate || null}
            </div>
          </div>
        </div>
        <div>
          <Accordion
            sx={{
              border: "none",
              boxShadow: "none",
              background: "none",
              margin: "10px 0",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                width: 200,
                color: "#42B19E",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              View Description
            </AccordionSummary>
            <AccordionDetails sx={{ border: "none" }}>
              <div className="init-details-desc">
                {initiativeDetailsData.description &&
                initiativeDetailsData.description !== "null"
                  ? initiativeDetailsData.description
                  : null}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }} />
        <div style={{ marginTop: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "30%",
            }}
          >
            <button
              style={{
                fontSize: "16px",
                fontWeight: 600,
                border: "none",
                background: "none",
                cursor: "pointer",
                color: impactTab === "imm" ? "#000" : "#999",
              }}
              onClick={() => setImpactTab("imm")}
            >
              IMM Impact
            </button>
            <button
              style={{
                fontSize: "16px",
                fontWeight: 600,
                border: "none",
                background: "none",
                cursor: "pointer",
                color: impactTab === "imm" ? "#999" : "#000",
              }}
              onClick={() => setImpactTab("original")}
            >
              Original Impact
            </button>
          </div>
          <div style={{ margin: "20px 0" }}>
            {impactTab === "imm" ? (
              <DataGrid
                rows={impactRows}
                columns={columns}
                pageSize={5}
                loading={impactRows?.length < 1}
                columnHeaderHeight={30}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                autoHeight
                hideFooter
                hideFooterSelectedRowCount
                disableColumnFilter
                disableColumnResize
                disableColumnSorting
                disableColumnMenu
                isCellEditable={handleIsCellEditable}
                onCellDoubleClick={handleCellDoubleClick}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                sx={tableStyles}
              />
            ) : (
              <DataGrid
                rows={originalRows}
                columns={columns}
                pageSize={5}
                columnHeaderHeight={30}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                autoHeight
                hideFooter
                hideFooterSelectedRowCount
                disableColumnFilter
                disableColumnResize
                disableColumnSorting
                disableColumnMenu
                sx={tableStyles}
              />
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            borderBottom: "1px solid #BFBFBF",
          }}
        />
        <div style={{ marginTop: "10px" }}>
          <h4>Category Allocation</h4>
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              width: "100%",
              height: "350px",
              // minHeight: "350px",
              // maxHeight: "600px",
              boxShadow: "0px 4px 16px 0px #3241FF29",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0 10px",
              padding: "10px 0",
              // overflow: "none",
              // overflowY: "auto",
            }}
          >
            {categoryAllocationLoading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CategoryAllocationChart data={filterCategory(categoryAllocationData)} />
                </div>
                <div
                  style={{
                    height: "300px",
                    borderRight: "1px solid #BFBFBF",
                  }}
                />
                <div
                  style={{
                    width: "40%",
                    margin: "0 50px",
                    height: "300px",
                    overflow: "none",
                    overflowX: "hidden",
                    overflowY: "auto",
                  }}
                >
                  {categoryAllocationData.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40%", fontWeight: "bold" }}>
                          Category
                        </div>
                        <div
                          style={{
                            width: "40%",
                            fontWeight: "bold",
                            textAlign: "right",
                          }}
                        >
                          Percentage
                        </div>
                        <div
                          style={{
                            width: "20%",
                            fontWeight: "bold",
                            marginRight: "10px",
                            textAlign: "right",
                          }}
                        >
                          Amount
                        </div>
                      </div>
                      {categoryAllocationData.length > 0 &&
                        categoryAllocationData.map((data) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // padding: "3px 0",
                            }}
                          >
                            <div style={{ width: "40%", fontSize: 14 }}>
                              {data.category}
                            </div>
                            <div
                              style={{
                                width: "40%",
                                textAlign: "right",
                                fontSize: 14,
                              }}
                            >
                              {currentEditCateogry !== data.category ? (
                                `${(
                                  Math.round(data.allocation * 100) / 100
                                ).toFixed(0)}%`
                              ) : (
                                // `${Intl.NumberFormat("de-DE", {
                                //   style: "decimal",
                                //   minimumFractionDigits: 2,
                                //   maximumFractionDigits: 2,
                                // }).format(data.allocation)}%`
                                <>
                                  <input
                                    type="number"
                                    value={data.allocation}
                                    onBlur={() => setCurrentCategory(null)}
                                    onChange={(e) =>
                                      handleUpdateCategoryAllocation(
                                        e.target.value,
                                        data.category
                                      )
                                    }
                                    style={{ width: "100px", fontSize: 14 }}
                                  />
                                  %
                                </>
                              )}
                              <IconButton
                                onClick={() =>
                                  setCurrentCategory(data.category)
                                }
                              >
                                <EditNoteIcon />
                              </IconButton>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                marginRight: "10px",
                                textAlign: "right",
                                fontSize: 14,
                              }}
                            >
                              {Intl.NumberFormat("de-DE", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                (data.allocation / 100) * data.imp_gross_profit
                              )}
                              {/* {data.allocation * data.imp_gross_profit} */}
                            </div>
                          </div>
                        ))}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: "40%", fontWeight: "bold" }}>
                          Total{" "}
                          <span style={{ color: "red" }}>
                            {categoryError && `(${categoryError})`}{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            width: "40%",
                            fontWeight: "bold",
                            textAlign: "right",
                            marginRight: "50px",
                          }}
                        >
                          {Math.round((totalCatPercentage * 100) / 100).toFixed(
                            0
                          )}
                          %
                        </div>
                        <div style={{ width: "20%" }}></div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginTop: "15px",
                          marginRight: "10px",
                        }}
                      >
                        <button
                          style={{
                            background: "none",
                            border: "1px solid #bababa",
                            marginRight: "5px",
                            color: "#bababa",
                            borderRadius: "5px",
                            padding: "3px 7px",
                            fontSize: 16,
                            cursor: "pointer",
                          }}
                          onClick={cancelCategoryAllocation}
                        >
                          Cancel
                        </button>
                        <button
                          style={{
                            background: "#2280EF",
                            border: "none",
                            color: "#fff",
                            borderRadius: "5px",
                            padding: "3px 7px",
                            fontSize: 16,
                            cursor: "pointer",
                          }}
                          onClick={saveCategoryAllocation}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: "10px", borderBottom: "1px solid #BFBFBF" }} />
        <h4>More Information</h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "20%",
            }}
          >
            <label
              htmlFor="financial-owner"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Financial Owner
            </label>
            <TextField
              placeholder="financial owner"
              variant="standard"
              fullWidth
              disabled={!isEditFinancialOwner}
              value={financialOwner}
              onChange={(e) => setFinancialOwner(e.target.value)}
              sx={{
                background: "#fff",
                border: "1px solid #BBC9FB ",
                borderRadius: 1,
                padding: "0 10px",
              }}
              InputProps={{
                sx: {
                  height: 40,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setIsEditFinancialOwner(!isEditFinancialOwner)
                      }
                      edge="end"
                    >
                      <EditNoteIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "15%",
            }}
          >
            <label
              htmlFor="second-owner"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Second Owner
            </label>
            <TextField
              placeholder="second owner"
              variant="standard"
              fullWidth
              disabled={!isEditSecondOwner}
              value={secondOwner}
              onChange={(e) => setSecondOwner(e.target.value)}
              sx={{
                background: "#fff",
                border: "1px solid #BBC9FB ",
                borderRadius: 1,
                padding: "0 10px",
              }}
              InputProps={{
                sx: {
                  height: 40,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsEditSecondOwner(!isEditSecondOwner)}
                      edge="end"
                    >
                      <EditNoteIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div> */}
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "20%",
            }}
          >
            <label
              htmlFor="complexity"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Complexity
            </label>
            <FormControl
              sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
              size="small"
            >
              <Select
                labelId="complexity"
                id="complexity"
                variant="standard"
                // disabled
                value={selectedComplexity}
                onChange={(e) => setSelectedComplexity(e.target.value)}
                // input={<OutlinedInput />}
                sx={{
                  background: "#FFF",
                  border: "1px solid #BBC9FB",
                  borderRadius: 1,
                  height: 40,
                  paddingLeft: 1,
                }}
                // MenuProps={MenuProps}
                MenuProps={{
                  PopoverClasses: {
                    paper: "custom-select-popover-init-details",
                  },
                }}
              >
                {complexityList.map((complexity) => (
                  <MenuItem
                    className="init-details-menu"
                    key={complexity}
                    value={complexity}
                  >
                    {complexity}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "20%",
            }}
          >
            <label
              htmlFor="status"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Status
            </label>
            <FormControl
              sx={{ display: "flex", flexDirection: "column" }}
              size="small"
            >
              <Select
                labelId="status"
                id="status"
                // disabled
                variant="standard"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                // input={<OutlinedInput />}
                sx={{
                  background: "#FFF",
                  border: "1px solid #BBC9FB",
                  borderRadius: 1,
                  height: 40,
                }}
                MenuProps={{
                  PopoverClasses: {
                    paper: "custom-select-popover-init-details",
                  },
                }}
              >
                <MenuItem className="init-details-menu" value={"NOT_STARTED"}>
                  <FiberManualRecordIcon
                    sx={{ padding: "2px", color: "darkgray" }}
                  />{" "}
                  {"Not Started"}
                </MenuItem>
                <MenuItem className="init-details-menu" value={"ON_TRACK"}>
                  <FiberManualRecordIcon
                    sx={{ padding: "2px", color: "green" }}
                  />{" "}
                  {"On Track"}
                </MenuItem>
                <MenuItem className="init-details-menu" value={"OFF_TRACK"}>
                  <FiberManualRecordIcon
                    sx={{ padding: "2px", color: "red" }}
                  />{" "}
                  {"Off Track"}
                </MenuItem>
                <MenuItem className="init-details-menu" value={"COMPLETED"}>
                  <FiberManualRecordIcon
                    sx={{ padding: "2px", color: "gray" }}
                  />{" "}
                  {"Completed"}
                </MenuItem>
                <MenuItem className="init-details-menu" value={"CANCELLED"}>
                  <FiberManualRecordIcon
                    sx={{ padding: "2px", color: "blue" }}
                  />{" "}
                  {"Cancelled"}
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "20%",
              position: "relative",
            }}
          >
            <label
              htmlFor="tags"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Tags
            </label>
            <input
              id="tags"
              disabled
              placeholder="Add tags here..."
              style={{
                padding: 10,
                fontSize: 16,
                borderRadius: 5,
                border: "1px solid #BBC9FB",
                outline: "none",
              }}
            />
            {/* <span style={{ color: "red", fontSize: 10 }}>Duplicate Value</span>
            <div
              style={{
                position: "absolute",
                top: 85,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {tagList.map((tag) => (
                <div
                  size="small"
                  label={tag}
                  style={{
                    padding: "2px 5px",
                    background: "#E9EDFB",
                    color: "#373B5C",
                    fontSize: 10,
                    borderRadius: 5,
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  {tag}
                  <span style={{ marginLeft: "5px" }}>
                    <ClearIcon fontSize="12" />
                  </span>
                </div>
              ))}
            </div> */}
          </div>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "80%",
              marginTop: 20,
            }}
          >
            <label
              htmlFor="risks"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Risks
            </label>
            <textarea
              id="risks"
              // disabled
              value={risks}
              onChange={(e) => setRisks(e.target.value)}
              style={{
                padding: 10,
                fontSize: 16,
                borderRadius: 5,
                border: "1px solid #BBC9FB",
                resize: "vertical",
                outline: "none",
              }}
            />
          </div>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "80%",
              marginTop: 20,
            }}
          >
            <label
              htmlFor="comments"
              style={{
                fontSize: "16px",
                color: "#373B5C",
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Comments
            </label>
            <textarea
              id="comments"
              // disabled
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              style={{
                padding: 10,
                fontSize: 16,
                borderRadius: 5,
                border: "1px solid #BBC9FB",
                resize: "vertical",
                outline: "none",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#fff",
                width: "164px",
                height: "50px",
                background: "#004DCD",
                border: "none",
                borderRadius: 10,
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              Save
            </button>
            <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                width: "164px",
                height: "50px",
                background: "#fff",
                border: "1px solid black",
                borderRadius: 10,
                marginLeft: 35,
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
            {/* <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                width: "164px",
                height: "50px",
                background: "#fff",
                border: "1px solid black",
                borderRadius: 10,
                marginLeft: 35,
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            >
              Come back
            </button> */}
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save current changes ?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={updateInitiativeDetails}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InitiativeDetails;
