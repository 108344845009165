import React, { useEffect, useState } from "react";
import { FormControl, Popover, Select } from "@mui/material";
import AccordionMultiCheckboxFilter from "./AccordionMultiCheckboxFilter";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCategory,
  changeCategorySector,
} from "../../../redux/reducers/InitiativeSlice";

const BCC = ["B001-Diapers", "B002-LAO Expense"];
const FamilyCare = ["B017-Bath Tissue"];
const AdultCare = ["B018-Senior Incont"];

const CategoryFilter = () => {
  //   const [selectedBCC, setSelectedBCC] = useState([]);
  //   const [selectedFamilyCare, setSelectedFamilyCare] = useState([]);
  //   const [selectedFemCare, setSelectedFemCare] = useState([]);
  //   const [selectedAdultCare, setSelectedAdultCare] = useState([]);
  const dispatch = useDispatch();
  const { currentCategory } = useSelector((state) => state.InitiativeSlice);

  const [filtersData, setFiltersData] = useState([]);
  const [reportLoaded, setReportLoaded] = useState(false);

  useEffect(() => {
    const fetchFilterData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/margin-walk`
      );

      const data = await response.data.data;
      console.log("Margin Walk Filter Reposnse ::", data);
      setFiltersData(data);

      const allSector = data.map((item) => item.sector);
      const allCategory = data.map((item) => item.category)?.flat(10);

      // console.log("All Sector ::", allSector);
      // console.log("All Cateogry ::", allCategory);

      dispatch(changeCategory(allCategory));
      dispatch(changeCategorySector(allSector));
    };

    fetchFilterData();
  }, []);

  useEffect(() => {
    if (window.report) {
      console.log("report loaded from category:::", window.report);
      const report = window.report;
      report.on("loaded", () => {
        setReportLoaded(true);
      });
    } else {
      setReportLoaded(true);
    }
    console.log("Current Category from Redux :::", currentCategory);
  }, [currentCategory, dispatch]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    const filteredSectors = filtersData
      .filter((item) => {
        return item.category.some((cat) => currentCategory?.includes(cat));
      })
      .map((item) => item.sector);

    const uniqueSectors = [...new Set(filteredSectors)];
    console.log("Current Category :::", currentCategory);
    console.log("Current Sector :::", uniqueSectors);
    dispatch(changeCategorySector(uniqueSectors));
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        margin: "0 10px",
        background: "#fff",
        padding: "5px 10px",
        borderRadius: 8,
        border: "1px solid #E8EFF7",
        boxShadow: "0px 2px 5px 0px #DAE8FF",
      }}
      onClick={!anchorEl ? handleOpen : console.log("keep open")}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        <InterestsOutlinedIcon sx={{ fontSize: "18px", color: "#373B5C" }} />
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "row" }}
        size="small"
      >
        <p style={{ margin: 0, color: "#373B5C" }}>Sector/Category</p>
        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          // renderValue={(selected) => "All"}
          open={false}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              width: "250px",
              marginLeft: "-10px",
              marginTop: "5px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "450px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #DAE8FF",
            },
          }}
        >
          <div>
            {filtersData.map((x, index) => (
              <AccordionMultiCheckboxFilter
                key={index}
                name={x.sector}
                dataList={x.category}
                // currentList={currentCategory}
                // setCurrentList={changeCategory}
              />
            ))}
            {/* <AccordionMultiCheckboxFilter
              name={"BCC"}
              dataList={BCC}
              currentList={selectedBCC}
              setCurrentList={setSelectedBCC}
              />
              <AccordionMultiCheckboxFilter
              name={"Family Care"}
              dataList={FamilyCare}
              currentList={selectedFamilyCare}
              setCurrentList={setSelectedFamilyCare}
              />
              <AccordionMultiCheckboxFilter
              name={"Adult Care"}
              dataList={AdultCare}
              currentList={selectedAdultCare}
              setCurrentList={setSelectedAdultCare}
              /> */}
            {reportLoaded && (
              <div
                style={{
                  display: "flex",
                  padding: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={handleClose}
                  fullWidth
                  variant="outlined"
                  color="primary"
                  style={{
                    background: "none",
                    border: "1px solid GrayText",
                    borderRadius: 5,
                    padding: "5px 10px",
                    margin: "0 5px",
                    fontSize: 14,
                    color: "GrayText",
                    cursor: "pointer",
                    width: "45%",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleApply}
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    background: "#2280EF",
                    color: "#fff",
                    border: "1px solid #2280EF",
                    borderRadius: 5,
                    padding: "5px 10px",
                    margin: "0 5px",
                    fontSize: 14,
                    cursor: "pointer",
                    width: "45%",
                  }}
                >
                  Apply Filter
                </button>
              </div>
            )}
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default CategoryFilter;
