import React from "react";
import MarginMapPBIReport from "./MarginMapPBIReport";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../../styles/marginmap.style.scss";

const MarginMapHome = () => {
  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      {" "}
      IMM Diagnostics
    </Typography>,
    <Typography key="3" color="text.primary" fontSize={14}>
      {" "}
      Margin Map
    </Typography>,
  ];

  return (
    <div className="container-fullpage">
      <div className="mm-links">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ margin: "2px 0", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div
        className="sc-link"
        style={{ margin: "2px 0", borderBottom: "1px solid #BFBFBF" }}
      />
      <MarginMapPBIReport />
    </div>
  );
};

export default MarginMapHome;
