import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  ListItemText,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { changeCategory } from "../../../redux/reducers/InitiativeSlice";

const AccordionMultiCheckboxFilter = ({ name, dataList }) => {
  const dispatch = useDispatch();
  const { currentCategory } = useSelector((state) => state.InitiativeSlice);

  useEffect(() => {
    console.log("Current Category from Redux :::", currentCategory);
  }, [dispatch, currentCategory]);

  const handleCheckbox = (option) => {
    let newSelectedOptions;
    if (currentCategory?.includes(option)) {
      newSelectedOptions = currentCategory?.filter((item) => item !== option);
      dispatch(changeCategory(newSelectedOptions));
    } else {
      let cat = currentCategory ? [...currentCategory] : [];
      newSelectedOptions = [...cat, option];
      dispatch(changeCategory(newSelectedOptions));
    }
  };

  return (
    <Accordion sx={{ border: "none", boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {name}
      </AccordionSummary>
      <AccordionDetails>
        {dataList.map((item) => (
          <MenuItem
            key={item}
            value={item}
            className="multi-select-checkbox-list"
            onClick={() => handleCheckbox(item)}
          >
            <Checkbox
              checked={currentCategory?.includes(item)}
              style={{ padding: "0px", color: "#2280ef" }}
            />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMultiCheckboxFilter;
