import React, { useEffect, useState } from "react";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import ElevatorOutlinedIcon from "@mui/icons-material/ElevatorOutlined";
import CustomMultiSelect from "./CustomMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCategory,
  changeIsAllCategory,
  changeIsAllMarket,
  changeIsAllSector,
  changeMarket,
  changeSector,
} from "../../../redux/reducers/PlaygroundSlice";
import "../../../styles/filter.style.scss";
import axios from "axios";

const FilterComponent = ({currentTab}) => {
  const [sectorList, setSectorList] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const dispatch = useDispatch();
  const {
    currentSector,
    currentCategorySector,
    currentMarket,
    currentCategory,
    isAllSector,
    isAllMarket,
    isAllCategory,
  } = useSelector((state) => state.PlaygroundSlice);

  useEffect(() => {}, [
    dispatch,
    currentSector,
    currentCategorySector,
    currentMarket,
    currentCategory,
    isAllSector,
    isAllMarket,
    isAllCategory,
  ]);

  useEffect(() => {
    const fetchFiltersList = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/playground-filters`
      );
      const data = await response.data.data;
      console.log("Filter Response Data :::", data);
      setSectorList(data.sector);
      setMarketList(data.market);
      setCategoryList(data.category);
    };

    console.log("Sector length :::", currentSector, isAllSector);

    fetchFiltersList();
  }, []);

  useEffect(() => {
    let filters= {
        sector: isAllSector ? [] : currentSector,
        market: isAllMarket ? [] : currentMarket,
        category: isAllCategory? [] : currentCategory
    };

    const fetchUpdatedFilterList = async () => {
      if (isAllMarket) {
        setMarketList([]);
        setCategoryList([]);
      }
      if (!isAllMarket) {
        setCategoryList([]);
      }
      setCategoryList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/new-playground-filters`,
        { filters }
      );

      const data = await response.data.data;
      console.log("Filtered Filter List :::", data);
      if (isAllMarket) {
        setMarketList(data.market);
        setCategoryList(data.category);
      }
      if (!isAllMarket) {
        setCategoryList(data.category);
      }
    };

    fetchUpdatedFilterList();
  }, [isAllSector, currentSector, isAllMarket, currentMarket]);

  return (
    <div className="init-filter-area">
      <div className="init-filter-title">
        {/* <h4>Statistics</h4>
        <p>Take look at overall matrice</p> */}
      </div>
      <div className="init-filter-list">
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Country"}
            options={marketList}
            currentTab={currentTab}
            selectedOptions={currentMarket}
            setSelectedOptions={changeMarket}
            isAllSelected={isAllMarket}
            setIsAllSelected={changeIsAllMarket}
            icon={<ElevatorOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
        <div className="init-filter-item">
          <CustomMultiSelect
            name={"Sector"}
            options={sectorList}
            currentTab={currentTab}
            selectedOptions={currentSector}
            setSelectedOptions={changeSector}
            isAllSelected={isAllSector}
            setIsAllSelected={changeIsAllSector}
            icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
          />
        </div>
        <div className="init-filter-item">
        <CustomMultiSelect
            name={"Category"}
            options={categoryList}
            currentTab={currentTab}
            selectedOptions={currentCategory}
            setSelectedOptions={changeCategory}
            isAllSelected={isAllCategory}
            setIsAllSelected={changeIsAllCategory}
            icon={<PersonOutlinedIcon sx={{ fontSize: "20px" }} />}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
