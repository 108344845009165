export const difficultyList = [
  "Quick and Easy",
  "Some Difficulty",
  "High Difficulty",
  "High Resource Needs",
];

export const ClassificationList = [
  "For Discussion",
  "Convert into Initiative",
  "Finalized",
  "Invalid",
];

export const prioriyList = ["High", "Medium", "Low"];
