import React from "react";
import InitiativeTabs from "./InitiativeTabs";
import PBIChartArea from "./PBIChartArea";
import { Switch, styled } from "@mui/material";
import InititativeFilters from "./InititativeFilters";
import GlobalFilter from "./GlobalFilter";
import "../../styles/screen.scss";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Homepage = () => {
  return (
    <div className="container" style={{paddingTop:"10px"}}>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gridColumn: "12",
          margin: "10px 0",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Off</Typography>
          <AntSwitch
            defaultChecked={showChart}
            onChange={(e) => {
              dispatch(handleShowChart(e.target.checked));
            }}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography>On</Typography>
        </Stack>
      </div>
      {showChart && <PBIChartArea />} */}
      <GlobalFilter />
      <PBIChartArea />
      <InititativeFilters />
      <InitiativeTabs />
    </div>
  );
};

export default Homepage;
