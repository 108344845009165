import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  DialogActions,
  Dialog,
  DialogTitle,
  CircularProgress,
  v,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { ReactComponent as EditNoteIcon } from "../../assets/images/Edit.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/cross.svg";
import { DateRangePicker } from "rsuite";
import { difficultyList, ClassificationList, prioriyList } from "./Constants";
import "rsuite/dist/rsuite.min.css";
import "../../styles/opportunity.scss";
import axios from "axios";

const CreateOpportunity = () => {
  const { id, year } = useParams();
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;
  const isInitialRender = useRef(true);
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveOpen, setSaveOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [opportunityName, setOpportunityName] = useState("");
  const [description, setDescription] = useState("");
  const [lever, setLever] = useState("");
  const [driver, setDriver] = useState("");
  const [sector, setSector] = useState("");
  const [sectorFromEdit, setSectorFromEdit] = useState("new");
  const [impact2024, setImpact2024] = useState(0);
  const [impact2025, setImpact2025] = useState(0);
  const [impact2026, setImpact2026] = useState(0);
  const [timeline, setTimeline] = useState([null, null]);
  const [calendarDate, setCalendarDate] = useState([new Date(), new Date()]);
  const [owner, setOwner] = useState("");
  const [secondOwner, setSecondOwner] = useState("");
  const [priority, setPriority] = useState("");
  const [classification, setClassification] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [includeInReports, setIncludeInReports] = useState("");
  const [tag, setTag] = useState("");
  const [comments, setComments] = useState("");
  const [categories, setCategories] = useState([]);
  const [tempCategories, setTempCategories] = useState([]);
  const [categoryAllocation, setCategoryAllocation] = useState([
    { category: "", allocation: "" },
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sectorList, setSectorList] = useState([]);
  const [leverList, setLeverList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteText, setDeletetext] = useState("");
  const isEditOpportunityPath = path.includes("edit-opportunity");

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const handleClickOpen = () => {
    setSaveOpen(true);
  };

  const handleClose = () => {
    setSaveOpen(false);
  };

  const handleDeleteClickOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const customMenuProps = {
    PaperProps: {
      sx: {
        marginTop: "5px",
        left: "1070px !important",
        border: "1px solid #BBC9FB",
      },
      className: "css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper",
    },
  };

  const selectStyle = {
    fontFamily: "Poppins, sans-serif !important",
    background: "#FFF",
    border: "1px solid #BBC9FB",
    borderRadius: "5px",
    height: "50px",
    paddingLeft: "8px",
    boxSizing: "border-box",
    width: "100%",
  };

  const rowStyle = {
    fontFamily: "Poppins, sans-serif !important",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "16px",
    marginBottom: "16px",
  };

  const columnStyle = {
    fontFamily: "Poppins, sans-serif !important",
    flex: "1 1 22%",
    marginRight: "30px",
  };

  const inputContainerStyle = {
    fontFamily: "Poppins, sans-serif !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
  };

  const impactYearStyle = {
    fontFamily: "Poppins, sans-serif !important",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",
    paddingTop: "8px",
  };

  const InputPropsStyle = {
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const InputCategoryStyle = {
    flex: "0 1 70%",
    marginLeft: "10px",
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const InputCategoryStyleTotal = {
    flex: "0 1 80%",
    margin: "20px 0px 0px 12px",
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "14px",
  };

  const InputCategoryPerStyle = {
    flex: "0 1 25%",
    marginLeft: "10px",
    height: 50,
    outline: "none",
    width: "100%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const InputCategoryPerStylePer = {
    flex: "0 1 15%",
    fontFamily: "Poppins, sans-serif !important",
    fontWeight: 600,
    fontSize: "14px",
    margin: "20px 0px 0px 0px",
  };
  const InputCategoryClose = {
    minWidth: "30px",
    minHeight: "30px",
    padding: "5px",
    lineHeight: "1",
    fontSize: "14px",
    textAlign: "center",
    borderRadius: "50%",
  };
  const commentPropStyle = {
    height: 50,
    outline: "none",
    width: "149%",
    boxSizing: "border-box",
    background: "white",
    fontFamily: "Poppins, sans-serif !important",
  };

  const labelStyleAlign = {
    fontFamily: "Poppins, sans-serif !important",
    fontSize: "16px",
    color: "#373B5C",
    fontWeight: 500,
    marginBottom: "5px",
    textAlign: "center",
  };

  useEffect(() => {
    setTempCategories([...categories]);
  }, []);

  useEffect(() => {
    const fetchFiltersList = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters`
      );
      const data = await response.data.data;
      console.log("Filter Response Data :::", data);
      setSectorList(data.sector);
      setLeverList(data.lever);
      setDriverList(data.driver);
    };
    fetchFiltersList();
  }, []);

  useEffect(() => {
    let filters = {
      sector: [],
      lever: [lever],
    };

    const fetchUpdatedDriver = async () => {
      setDriverList([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/new`,
        { filters }
      );
      const data = await response.data.data;
      console.log("Filtered Filter List :::", data);
      setDriverList(data.driver);
    };
    if (lever) {
      fetchUpdatedDriver();
    }
  }, [lever]);

  useEffect(() => {
    const fetchUpdatedCategory = async () => {
      setCategories([]);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/filter-category-by-sector`,
        { sector: sector }
      );
      const data = await response.data.data;
      console.log("cactegory data:::", data);
      if (sectorFromEdit === sector) {
        console.log(sectorFromEdit === sector);
        setCategories(categoryAllocation);
      } else {
        setCategories(data);
      }
    };
    if (sector) {
      fetchUpdatedCategory();
    }
  }, [sector]);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/get-details`,
            { src_id: id, cal_year: year }
          );
          const data = await response.data.data;
          setComments(data?.comments);
          setOpportunityName(data?.opportunity);
          setDescription(data?.opportunity_description);
          setLever(data?.lever);
          setDriver(data?.driver);
          setSectorFromEdit(data?.sector);
          setSector(data?.sector);
          setCategories(data?.category_allocation);
          setCategoryAllocation(data?.category_allocation);
          setImpact2024(data?.gp_2024);
          setImpact2025(data?.gp_2025);
          setImpact2026(data?.gp_2026);
          if (data?.start_date && data?.end_date) {
            const start = new Date(data?.start_date);
            const end = new Date(data?.end_date);
            setTimeline([start, end]);
          }
          setOwner(data?.owner);
          setSecondOwner(data?.delegate);
          setPriority(data?.priority);
          setClassification(data?.classification);
          setDifficulty(data?.complexity);
          setIncludeInReports(data?.is_report_included);
          setTag(data?.tags);
          setComments(data?.comments);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setLoading(false);
      setOpportunityName("");
      setDescription("");
      setLever("");
      setDriver("");
      setSector("");
      setImpact2024(0);
      setImpact2025(0);
      setImpact2026(0);
      setTimeline(null, null);
      setOwner("");
      setSecondOwner("");
      setPriority("");
      setClassification("");
      setDifficulty("");
      setIncludeInReports("");
      setTag("");
      setComments("");
    }
  }, [id]);

  const handleImpactChange = (event, setValue) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (inputValue) {
      const formattedValue = Intl.NumberFormat("de-DE", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(inputValue);
      setValue(formattedValue);
    } else {
      setValue(0);
    }
  };

  const totalAllocation = categories.reduce((total, category) => {
    const allocation = parseFloat(category.allocation);
    return total + (isNaN(allocation) ? 0 : allocation);
  }, 0);

  const validateForm = () => {
    const newErrors = {};
    if (!opportunityName) {
      newErrors.opportunityName = "Opportunity Name is required";
    } else if (opportunityName?.length > 200) {
      newErrors.opportunityName =
        "Opportunity Name does not exceed 200 charactors";
    }
    if (description?.length > 300) {
      newErrors.description = "Description does not exceed 300 charactors";
    }
    if (!lever) {
      newErrors.lever = "Lever is required";
    }
    if (!sector) {
      newErrors.sector = "Sector is required";
    }
    if(totalAllocation !== 0 && totalAllocation !== 100){
      newErrors.total = "Total Allocation should be 100%";
    }
    if (owner?.length > 100) {
      newErrors.owner = "Owner does not exceed 100 charactors";
    }
    if (secondOwner?.length > 100) {
      newErrors.secondOwner = "Second Owner does not exceed 100 charactors";
    }
    if (comments?.length > 500) {
      newErrors.comments = "Comments does not exceed 500 charactors";
    }
    if (!timeline) {
      newErrors.timeline = "Start Date and End Date is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors)?.length === 0;
  };

  const handleBlur = (e) => {
    validateForm();
  };

  const handleLeverChange = (e) => {
    setLever(e.target.value);
    setDriver("");
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      validateForm();
    }
  }, [lever, sector]);

  function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const updateOpportunityDetails = async (e) => {
    e.preventDefault();
    handleClose();
    if (!validateForm()) {
      return;
    }
    if (isEditOpportunityPath) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/edit`,
          {
            src_id: id,
            opportunity: opportunityName,
            opportunity_description: description,
            priority: priority,
            sector: sector,
            lever: lever,
            driver: driver,
            category_allocation: categories || [],
            category_allocation:
              categories.map(({ category, allocation }) => ({
                category,
                allocation,
              })) || [],
            gp_2024: parseFloat(impact2024),
            gp_2025: parseFloat(impact2025),
            gp_2026: parseFloat(impact2026),
            owner: owner,
            delegate: secondOwner,
            classification: classification,
            complexity: difficulty,
            tags: tag,
            is_report_included: includeInReports,
            comments: comments,
            start_date:
              timeline[0] === null ? "" : formatDateToYMD(timeline[0]),
            end_date: timeline[1] === null ? "" : formatDateToYMD(timeline[1]),
          }
        );
        const resp = await response.data;
        alert("Opportunity saved Successfully");
        history.push("/");
        console.log("Update resp :::", resp);
        window.scrollTo(0, 0);
      } catch (error) {
        alert("Something went wrong, Please try again!");
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/save`,
          {
            opportunity: opportunityName,
            opportunity_description: description,
            priority: priority,
            sector: sector,
            lever: lever,
            driver: driver,
            category_allocation: categories || [],
            gp_2024: parseFloat(impact2024),
            gp_2025: parseFloat(impact2025),
            gp_2026: parseFloat(impact2026),
            owner: owner,
            delegate: secondOwner,
            classification: classification,
            complexity: difficulty,
            tags: tag,
            is_report_included: includeInReports,
            comments: comments,
            start_date:
              timeline[0] === null ? "" : formatDateToYMD(timeline[0]),
            end_date: timeline[0] === null ? "" : formatDateToYMD(timeline[1]),
          }
        );
        const resp = await response.data;
        alert("Opportunity saved Successfully");
        history.push("/");
        console.log("Update resp :::", resp);
        window.scrollTo(0, 0);
      } catch (error) {
        console.error("Error while saving Opportunity ::", error);
        alert("Something went wrong, Please try again!");
      }
    }
  };

  console.log(impact2024, "gp_2024")

  const deleteOpportunity = async (e) => {
    handleDeleteClose();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/opportunity/delete`,
        {
          src_id: id,
          user: userInfo?.name,
          change_reason: deleteText,
        }
      );
      const resp = await response.data;
      alert("Opportunity deleted Successfully");
      history.push("/");
      console.log("deleting opportunity:::", resp);
      window.scrollTo(0, 0);
    } catch (error) {
      alert("Something went wrong, Please try again!");
    }
  };

  const handleDateSelect = (value) => {
    setTimeline(value);
    if (value[0] && value[1]) {
      setCalendarDate([value[0], value[0]]);
    }
  };

  const handleCategoryChange = (index, event) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, category: event.target.value } : category
      )
    );
  };

  const handlePercentageChange = (index, event) => {
    setCategories((prevCategories) =>
      prevCategories.map((category, i) =>
        i === index ? { ...category, allocation: event.target.value } : category
      )
    );
  };

  // const removeCategory = (index) => {
  //   const newCategories = tempCategories.filter((_, i) => i !== index);
  //   setTempCategories(newCategories);
  // };

  // const handleSaveCategory = () => {
  //   setCategories([...tempCategories]);
  //   setIsDropdownOpen(false);
  // };

  // const handleCancelCategory = () => {
  //   setTempCategories(categories);
  //   setIsDropdownOpen(false);
  // };

  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            background: "#ffffff90",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            left: 0,
            top: 0,
            zIndex: 99,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div style={{ margin: "30px" }}>
        <div className="heading">
          {isEditOpportunityPath ? "Edit Opportunity" : "Create Opportunity"}
        </div>
        <div className="sub-heading">Opportunity details</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Opportunity ID</label>
              <input
                type="text"
                disabled
                value={id ? id : "Opportunity ID"}
                className="input-style"
              />
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Opportunity Name</label>
              <TextField
                value={opportunityName}
                placeholder="Opportunity Name"
                onChange={(e) => setOpportunityName(e.target.value)}
                onBlur={handleBlur}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.opportunityName && (
                <span style={{ color: "red" }}>{errors.opportunityName}</span>
              )}
            </div>
          </div>
          <div style={{ ...columnStyle, flex: "2 2 45%" }}>
            <div
              className="init-details-form"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                width: "206%",
              }}
            >
              <label className="label-type">Description</label>
              <TextField
                value={description}
                placeholder="Add description..."
                onChange={(e) => setDescription(e.target.value)}
                InputProps={{
                  sx: {
                    height: 50,
                    outline: "none",
                    width: "103%",
                    boxSizing: "border-box",
                    background: "white",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.description && (
                <span style={{ color: "red" }}>{errors.description}</span>
              )}
            </div>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Lever</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="lever"
                  id="lever"
                  variant="standard"
                  value={lever}
                  onChange={handleLeverChange}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {leverList.map((lever) => (
                    <MenuItem key={lever} value={lever}>
                      {lever}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.lever && (
                <span style={{ color: "red" }}>{errors.lever}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Driver</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="driver"
                  id="driver"
                  variant="standard"
                  value={driver}
                  onChange={(e) => setDriver(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {driverList.map((driver) => (
                    <MenuItem key={driver} value={driver}>
                      {driver}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type-mand">Sector</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="sector"
                  id="sector"
                  variant="standard"
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {sectorList.map((sector) => (
                    <MenuItem key={sector} value={sector}>
                      {sector}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.sector && (
                <span style={{ color: "red" }}>{errors.sector}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Category</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                }}
                size="small"
              >
                <Select
                  id="category"
                  variant="standard"
                  sx={selectStyle}
                  displayEmpty
                  MenuProps={customMenuProps}
                  open={isDropdownOpen}
                  onOpen={() => setIsDropdownOpen(true)}
                  onClose={() => setIsDropdownOpen(false)}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  <div>
                    {categories?.map((category, index) => (
                      <div
                        key={index}
                        className="category-input"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <TextField
                          disabled
                          type="text"
                          placeholder="Enter category name"
                          value={category.category}
                          onChange={(e) => handleCategoryChange(index, e)}
                          style={{
                            ...InputCategoryStyle,
                          }}
                          InputProps={{
                            style: { fontSize: "14px" },
                          }}
                        />
                        <TextField
                          value={category.allocation}
                          onBlur={handleBlur}
                          placeholder="0"
                          onChange={(e) => handlePercentageChange(index, e)}
                          style={InputCategoryPerStyle}
                          InputProps={{
                            style: { fontSize: "14px" },
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                        {/* <Button
                          onClick={() => removeCategory(index)}
                          style={InputCategoryClose}
                          disabled={tempCategories.length === 1}
                        >
                          <CloseIcon />
                        </Button> */}
                      </div>
                    ))}
                  </div>
                  {categories.length > 0 && (
                    <div
                      className="category-input"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={InputCategoryStyleTotal}>Total</div>
                      <div style={InputCategoryPerStylePer}>
                        {totalAllocation} %
                      </div>
                    </div>
                  )}
                  {/* <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        onClick={addCategory}
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#fff",
                          width: "55px",
                          height: "35px",
                          background: "#2280EF",
                          border: "none",
                          borderRadius: 10,
                          cursor: "pointer",

                          margin: "0px 10px 10px 10px",
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        onClick={() => handleCancelCategory()}
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          width: "60px",
                          height: "35px",
                          background: "#fff",
                          border: "1px solid #efefef",
                          borderRadius: 10,
                          marginLeft: 35,
                          cursor: "pointer",
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveCategory}
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#fff",
                          width: "55px",
                          height: "35px",
                          background: "#2280EF",
                          border: "none",
                          borderRadius: 10,
                          cursor: "pointer",

                          margin: "0px 10px 10px 10px",
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  ></div>
                </Select>
              </FormControl>
              {errors.total && (
                <span style={{ color: "red" }}>{errors.total}</span>
              )}
            </div>
          </div>
        </div>
        <div className="sub-heading">Potential GP Impact</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div
              style={{
                ...impactYearStyle,
                background: "#caf5ff",
              }}
            >
              <label style={labelStyleAlign}>2024</label>
              <TextField
                value={impact2024}
                placeholder="$8,990"
                onChange={(event) => handleImpactChange(event, setImpact2024)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div style={columnStyle}>
            <div
              style={{
                ...impactYearStyle,
                background: "#f7d0ac",
              }}
            >
              <label style={labelStyleAlign}>2025</label>
              <TextField
                type="text"
                value={impact2025}
                placeholder="$8,990"
                onChange={(event) => handleImpactChange(event, setImpact2025)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div style={columnStyle}>
            <div
              style={{
                ...impactYearStyle,
                background: "#D7F5C9",
              }}
            >
              <label style={labelStyleAlign}>2026</label>
              <TextField
                type="text"
                value={impact2026}
                placeholder="$8,990"
                onChange={(event) => handleImpactChange(event, setImpact2026)}
                InputProps={{
                  sx: {
                    height: 50,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    boxSizing: "border-box",
                    background: "white",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div>
            <div style={columnStyle}>
              <div style={inputContainerStyle}>
                <label className="label-type">Timeline</label>

                <DateRangePicker
                  onBlur={handleBlur}
                  value={timeline}
                  format="dd/MM/yyyy"
                  height="50px"
                  sx={{ border: "1px solid #BBC9FB", width: "900px" }}
                  onChange={handleDateSelect}
                  showOneCalendar={true}
                  ranges={[]}
                  onOk={(value) => setTimeline(value)}
                  cleanable={false}
                />
                {errors.timeline && (
                  <span style={{ color: "red" }}>{errors.timeline}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="sub-heading">More details</div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="owner name"
                value={owner}
                onChange={(e) => setOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.owner && (
                <span style={{ color: "red" }}>{errors.owner}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Second Owner</label>
              <TextField
                onBlur={handleBlur}
                type="text"
                placeholder="owner name"
                value={secondOwner}
                onChange={(e) => setSecondOwner(e.target.value)}
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.secondOwner && (
                <span style={{ color: "red" }}>{errors.secondOwner}</span>
              )}
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Priority</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="priority"
                  id="priority"
                  variant="standard"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {prioriyList.map((priority) => (
                    <MenuItem key={priority} value={priority}>
                      {priority}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Classification</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="classification"
                  id="classification"
                  variant="standard"
                  value={classification}
                  onChange={(e) => setClassification(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {ClassificationList.map((classification) => (
                    <MenuItem key={classification} value={classification}>
                      {classification}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={rowStyle}>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Difficulty</label>
              <FormControl
                sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
                size="small"
              >
                <Select
                  labelId="difficulty"
                  id="difficulty"
                  variant="standard"
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  {difficultyList.map((difficulty) => (
                    <MenuItem key={difficulty} value={difficulty}>
                      {difficulty}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Include in Reports</label>
              <FormControl
                sx={{
                  minWidth: 150,
                  display: "flex",
                  flexDirection: "column",
                  height: "50px",
                }}
                size="small"
              >
                <Select
                  labelId="include-in-reports"
                  id="include-in-reports"
                  variant="standard"
                  value={includeInReports}
                  onChange={(e) => setIncludeInReports(e.target.value)}
                  sx={selectStyle}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={columnStyle}>
            <div style={inputContainerStyle}>
              <label className="label-type">Tag</label>
              <TextField
                type="text"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                placeholder="add Tag..."
                InputProps={{
                  sx: InputPropsStyle,
                  endAdornment: (
                    <InputAdornment position="end">
                      <EditNoteIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ ...columnStyle, flex: "2 2 45%" }}>
          <div
            className="init-details-form"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "50%",
            }}
          >
            <label className="label-type">Comments</label>
            <TextField
              onBlur={handleBlur}
              id="comments"
              placeholder="Add comments..."
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              InputProps={{
                sx: commentPropStyle,
                endAdornment: (
                  <InputAdornment position="end">
                    <EditNoteIcon />
                  </InputAdornment>
                ),
              }}
            />
            {errors.comments && (
              <span style={{ color: "red" }}>{errors.comments}</span>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            marginTop: 20,
          }}
        >
          <button
            style={{
              fontSize: "15px",
              fontWeight: 500,
              color: "#fff",
              width: "164px",
              height: "42px",
              background: "#004DCD",
              border: "none",
              borderRadius: 10,
              cursor: "pointer",
              fontFamily: "Poppins,sans-serif !important",
            }}
            onClick={handleClickOpen}
          >
            Save
          </button>
          <button
            style={{
              fontSize: "15px",
              fontWeight: 500,
              width: "164px",
              height: "42px",
              background: "#fff",
              border: "1px solid black",
              borderRadius: 10,
              marginLeft: 35,
              cursor: "pointer",
              fontFamily: "Poppins,sans-serif !important",
            }}
            onClick={() => history.push("/")}
          >
            Cancel
          </button>
          {id && (
            <button
              onClick={handleDeleteClickOpen}
              style={{
                fontSize: "15px",
                fontWeight: 500,
                color: "#fff",
                width: "164px",
                height: "42px",
                background: "red",
                border: "none",
                borderRadius: 10,
                cursor: "pointer",
                marginLeft: 35,
                fontFamily: "Poppins,sans-serif !important",
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      <Dialog
        open={saveOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save Opportunity ?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={updateOpportunityDetails}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: "80%", // Dialog will take up 80% of the viewport width
            maxWidth: "800px", // But will not exceed 800px
            margin: "auto",
            padding: "20px", // Ensure the dialog stays centered
          },
        }}
      >
        <DialogTitle sx={{ paddingLeft: "0px" }}>
          Are you sure you want to delete opportunity ?
        </DialogTitle>
        <label className="label-type-mand">Reason</label>
        <TextField
          value={deleteText}
          placeholder="Please add Reason to delete Opportunity"
          onChange={(e) => setDeletetext(e.target.value)}
        />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleDeleteClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            autoFocus
            onClick={deleteOpportunity}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateOpportunity;
