import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const CustomDataGrid = styled(DataGrid)(({ theme }) => {
  const dynamicStyles = {};
  const columnsStartingWith2024 = [
    "nsv_2024",
    // "2024NSV YTG",
    "gp_2024",
    // "2024GP($) YTG",
    "gm_2024",
  ];
  const columnsStartingWith2025 = [
    "nsv_2025",
    // "2025NSV YTG",
    "gp_2025",
    // "2025GP($) YTG",
    "gm_2025",
  ];
  const columnsStartingWith2026 = [
    "nsv_2026",
    // "2026NSV YTG",
    "gp_2026",
    // "2026GP($) YTG",
    "gm_2026",
  ];

  columnsStartingWith2024.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#3FBAD91A",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#3FBAD91A",
    };
  });
  columnsStartingWith2025.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#E4D4C799",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#E4D4C799",
    };
  });
  columnsStartingWith2026.forEach((field) => {
    dynamicStyles[`& .MuiDataGrid-cell[data-field="${field}"]`] = {
      backgroundColor: "#5DB1341A",
    };
    dynamicStyles[`& .MuiDataGrid-columnHeader[data-field="${field}"]`] = {
      backgroundColor: "#5DB1341A",
    };
  });

  return {
    "& .MuiDataGrid-root": {
      //   width: "100%",
    },
    "& .MuiDataGrid-columnHeader--emptyGroup": {
      visibility: "hidden",
      border: "none",
    },
    "& .MuiDataGrid-columnHeader--filledGroup": {
      borderRadius: "10px 10px 0 0",
      //   height: "40px !important",
      //   marginTop: "30px",
      fontWeight: 400,
    },
    '& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-nsv_2024-|"]': {
      backgroundColor: "#3FBAD91A",
      fontWeight: "bold",
    },
    '& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-nsv_2025-|"]': {
      backgroundColor: "#E4D4C799",
    },
    '& .MuiDataGrid-columnHeader--filledGroup[data-fields*="|-nsv_2026-|"]': {
      backgroundColor: "#5DB1341A",
    },
    "& .MuiDataGrid-cell": {
      fontSize: "12px",
    },
    "& .MuiDataGrid-columnHeader": {
      backgroundColor: "#DFE4EB",
      "& .MuiDataGrid-iconButtonContainer": {
        visibility: "visible",
      },
      "& .MuiDataGrid-sortIcon": {
        opacity: 1,
        visibility: "visible",
      },

      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#334d74",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    "& .MuiDataGrid-footerContainer ": {
      backgroundColor: "lightblue", // Example background color
      fontWeight: "bold",
      //   textAlign: "center",
    },
    ...dynamicStyles,
  };
});
