import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTab: "initiative-overview",
  currentSubTab: "imm-impact-vs-plan",
  currentDriver: [],
  
  currentSector: [],
  currentMarket: [],
  currentCategory: [],

  isAllSector: true,
  isAllMarket: true,
  isAllCategory: true,
  
  currentCategorySector: [],
  selectedInitiative: [],
  selectedOwner: [],
  selectedSecondOwner: [],
  selectedFinancialOwner: [],
  selectedIsValid: "yes",
  selectedComplexity: "",
  selectedClassification: "",
  selectedTags: [],
  selectedStatus: [],
  //  : ["NOT_STARTED", "ON_TRACK", "OFF_TRACK"],
  selectedGP2024Min: 0,
  selectedGP2024Max: 0,
  selectedGP2025Min: 0,
  selectedGP2025Max: 0,
  selectedGP2026Min: 0,
  selectedGP2026Max: 0,
  overviewStatus: "idle",
  playgroundTableData: [],
  overviewTableRowLength: 0,
  financialTableRowLength: 0,
  overviewPaginationModel: {
    pageSize: 5,
    page: 0,
  },
  overviewPageNo: 1,
  pageLimit: 10,
  financialStatus: "idle",
  playgroundPlanConfidenceTableData: [],
  playgroundHeadwindsTailwindsTableData:[],
  sortModel: [],
  tablePostData: {
    pageNo: 1,
    limit: 5,
    sortBy: "gp_2024",
    direction: "desc",
  },
};

const createReducer = (key) => (state, action) => {
  state[key] = action.payload;
};


export const fetchScoreCardData = createAsyncThunk(
  "scorecard/fetchScorecard",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/scorecard/kpis`,
      postData
    );
    console.log("Scorecard Response :::", response);
    return response?.data;
  }
);

export const fetchPlaygroundGetTargetMarginData = createAsyncThunk(
  "table/fetchPlaygroundGetTargetMarginData",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-target-margin`,
      postData
    );
    console.log("Playground Response :::", response);

    const targetMarginData = response.data.data.map((row, index) => ({
      ...row,
      id: index, 
      editable:true,
    }));

    // let totalCount = response?.data?.data?.totalCount;
    // // return response?.data;
    console.log("qqqquniqID :::", targetMarginData);
    return { data: targetMarginData };
  }
);

export const fetchPlaygroundGetHeadwindTailwindsData = createAsyncThunk(
  "table/fetchPlaygroundGetHeadwindTailwindsData",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-headwinds-tailwinds`,
      postData
    );
    console.log("Playground Response :::", response);

    const targetMarginData = response.data.data.map((row, index) => ({
      ...row,
      id: index, 
      editable:true,
    }));

    // let totalCount = response?.data?.data?.totalCount;
    // // return response?.data;
    console.log("qqqquniqID :::", targetMarginData);
    return { data: targetMarginData };
  }
);

export const fetchPlaygroundGetPlanConfidenceData = createAsyncThunk(
  "table/fetchPlaygroundGetPlanConfidenceData",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-plan-confidence`,
      postData
    );
    console.log("Playground Response :::", response);

    const targetMarginData = response.data.data.map((row, index) => ({
      ...row,
      id: index, 
      editable:true,
    }));

    // let totalCount = response?.data?.data?.totalCount;
    // // return response?.data;
    console.log("qqqquniqID :::", targetMarginData);
    return { data: targetMarginData };
  }
);


export const fetchInitiativeFinancialData = createAsyncThunk(
  "table/fetchInitiativeInitiativeData",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/home/imm-financial`,
      postData
    );
    console.log("financial Response :::", response);
    let dataWithUniqueId = response?.data?.data?.logs?.map((row, index) => {
      if (row?.name !== "Total") {
        return { ...row, uid: row.src_id + index };
      } else {
        return { ...row, uid: " " };
      }
    });
    let totalCount = response?.data?.data?.totalCount;
    console.log("financial Response uniqID :::", dataWithUniqueId);
    return { data: dataWithUniqueId, totalCount };
    // return response?.data;
  }
);

const PlaygroundSlice = createSlice({
  name: "Playground",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
    changeSubtab: (state, action) => {
      state.currentSubTab = action.payload;
    },
    handleSortModel: (state, action) => {
      state.sortModel = [...action.payload];
    },
    handlePageLimitChange: (state, action) => {
      state.pageLimit = action.payload;
    },
    handleOverviewPageNo: (state, action) => {
      state.overviewPageNo = action.payload;
    },
    handleOverviewPagination: (state, action) => {
      state.overviewPaginationModel = action.payload;
    },
    handleChaangeTablePostData: (state, action) => {
      state.tablePostData = action.payload;
    },
    changeCategorySector: createReducer("currentCategorySector"),
    
    changeSector: createReducer("currentSector"),
    changeCategory: createReducer("currentCategory"),
    changeMarket: createReducer("currentMarket"),
    
    changeDriver: createReducer("currentDriver"),

    changeIsAllSector: createReducer("isAllSector"),
    changeIsAllMarket: createReducer("isAllMarket"),
    changeIsAllCategory: createReducer("isAllCategory"),
    
    changeSelectedInitiative: createReducer("selectedInitiative"),
    changeSelectedOwner: createReducer("selectedOwner"),
    changeSelectedSecondOwner: createReducer("selectedSecondOwner"),
    changeSelectedFinancialOwner: createReducer("selectedFinancialOwner"),
    changeSelectedIsValid: createReducer("selectedIsValid"),
    changeSelectedComplexity: createReducer("selectedComplexity"),
    changeSelectedClassification: createReducer("selectedClassification"),
    changeSelectedTags: createReducer("selectedTags"),
    changeSelectedStatus: createReducer("selectedStatus"),
    changeSelectedGP2024Min: createReducer("selectedGP2024Min"),
    changeSelectedGP2024Max: createReducer("selectedGP2024Max"),
    changeSelectedGP2025Min: createReducer("selectedGP2025Min"),
    changeSelectedGP2025Max: createReducer("selectedGP2025Max"),
    changeSelectedGP2026Min: createReducer("selectedGP2026Min"),
    changeSelectedGP2026Max: createReducer("selectedGP2026Max"),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaygroundGetTargetMarginData.pending, (state) => {
        state.overviewStatus = "loading";
        state.playgroundTableData = [];
        state.playgroundPlanConfidenceTableData = [];
      })
      .addCase(fetchPlaygroundGetTargetMarginData.rejected, (state) => {
        state.overviewStatus = "failed";
        state.playgroundTableData = [];
        state.playgroundPlanConfidenceTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchPlaygroundGetTargetMarginData.fulfilled, (state, action) => {
        state.overviewStatus = "succeeded";
        state.playgroundTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      })
      .addCase(fetchPlaygroundGetPlanConfidenceData.pending, (state) => {
        state.financialStatus = "loading";
        state.playgroundPlanConfidenceTableData = [];
        state.playgroundTableData = [];
      })
      .addCase(fetchPlaygroundGetPlanConfidenceData.rejected, (state) => {
        state.financialStatus = "failed";
        state.playgroundTableData = [];
        state.playgroundPlanConfidenceTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchPlaygroundGetPlanConfidenceData.fulfilled, (state, action) => {
        state.financialStatus = "succeeded";
        state.playgroundPlanConfidenceTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      })


      .addCase(fetchPlaygroundGetHeadwindTailwindsData.pending, (state) => {
        state.financialStatus = "loading";
        state.playgroundHeadwindsTailwindsTableData = [];
        state.playgroundTableData = [];
      })
      .addCase(fetchPlaygroundGetHeadwindTailwindsData.rejected, (state) => {
        state.financialStatus = "failed";
        state.playgroundTableData = [];
        state.playgroundHeadwindsTailwindsTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchPlaygroundGetHeadwindTailwindsData.fulfilled, (state, action) => {
        state.financialStatus = "succeeded";
        state.playgroundHeadwindsTailwindsTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      });

      
  },
});

export const {
  changeTab,
  changeSubtab,
  handleShowChart,
  handleSortModel,
  handlePageLimitChange,
  handleOverviewPageNo,
  changeCategory,
  changeCategorySector,
  changeSector,
  changeMarket,
  changeDriver,
  changeIsAllSector,
  changeIsAllMarket,
  changeIsAllCategory,
  handleOverviewPagination,
  handleChaangeTablePostData,
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGP2024Max,
  changeSelectedGP2024Min,
  changeSelectedGP2025Max,
  changeSelectedGP2025Min,
  changeSelectedGP2026Max,
  changeSelectedGP2026Min,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
} = PlaygroundSlice.actions;

export default PlaygroundSlice.reducer;
