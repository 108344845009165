import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTab: "initiative-overview",
  currentSubTab: "imm-impact-vs-plan",
  currentSector: [],
  currentLever: [],
  currentDriver: [],
  isAllSector: true,
  isAllLever: true,
  isAllDriver: true,
  currentCategory: [],
  currentCategorySector: [],
  selectedInitiative: [],
  selectedOwner: [],
  selectedSecondOwner: [],
  selectedFinancialOwner: [],
  selectedIsValid: "yes",
  selectedComplexity: "",
  selectedClassification: "",
  selectedTags: [],
  selectedStatus: [],
  //  : ["NOT_STARTED", "ON_TRACK", "OFF_TRACK"],
  selectedGP2024Min: 0,
  selectedGP2024Max: 0,
  selectedGP2025Min: 0,
  selectedGP2025Max: 0,
  selectedGP2026Min: 0,
  selectedGP2026Max: 0,
  overviewStatus: "idle",
  overviewTableData: [],
  overviewTableRowLength: 0,
  financialTableRowLength: 0,
  overviewPaginationModel: {
    pageSize: 5,
    page: 0,
  },
  overviewPageNo: 1,
  pageLimit: 10,
  financialStatus: "idle",
  financialTableData: [],
  sortModel: [],
  tablePostData: {
    pageNo: 1,
    limit: 5,
    sortBy: "gp_2024",
    direction: "desc",
  },
};

const createReducer = (key) => (state, action) => {
  state[key] = action.payload;
};

export const fetchInitiativeOverviewData = createAsyncThunk(
  "table/fetchInitiativeOverviewData",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/home/initiative-overview`,
      postData
    );
    console.log("Overview Response :::", response);
    let dataWithUniqueId = response?.data?.data?.logs?.map((row, index) => {
      if (row?.name !== "Total") {
        return { ...row, uid: row.src_id + index };
      } else {
        return { ...row, uid: " " };
      }
    });
    let totalCount = response?.data?.data?.totalCount;
    // return response?.data;
    console.log("Overview Response uniqID :::", dataWithUniqueId);
    return { data: dataWithUniqueId, totalCount };
  }
);

export const fetchInitiativeFinancialData = createAsyncThunk(
  "table/fetchInitiativeInitiativeData",
  async (postData) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/api/home/imm-financial`,
      postData
    );
    console.log("financial Response :::", response);
    let dataWithUniqueId = response?.data?.data?.logs?.map((row, index) => {
      if (row?.name !== "Total") {
        return { ...row, uid: row.src_id + index };
      } else {
        return { ...row, uid: " " };
      }
    });
    let totalCount = response?.data?.data?.totalCount;
    console.log("financial Response uniqID :::", dataWithUniqueId);
    return { data: dataWithUniqueId, totalCount };
    // return response?.data;
  }
);

const InitiativeSlice = createSlice({
  name: "Inititative",
  initialState,
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload;
    },
    changeSubtab: (state, action) => {
      state.currentSubTab = action.payload;
    },
    handleSortModel: (state, action) => {
      state.sortModel = [...action.payload];
    },
    handlePageLimitChange: (state, action) => {
      state.pageLimit = action.payload;
    },
    handleOverviewPageNo: (state, action) => {
      state.overviewPageNo = action.payload;
    },
    handleOverviewPagination: (state, action) => {
      state.overviewPaginationModel = action.payload;
    },
    handleChaangeTablePostData: (state, action) => {
      state.tablePostData = action.payload;
    },
    changeCategory: createReducer("currentCategory"),
    changeCategorySector: createReducer("currentCategorySector"),
    changeSector: createReducer("currentSector"),
    changeLever: createReducer("currentLever"),
    changeDriver: createReducer("currentDriver"),
    changeIsAllSector: createReducer("isAllSector"),
    changeIsAllLever: createReducer("isAllLever"),
    changeIsAllDriver: createReducer("isAllDriver"),
    changeSelectedInitiative: createReducer("selectedInitiative"),
    changeSelectedOwner: createReducer("selectedOwner"),
    changeSelectedSecondOwner: createReducer("selectedSecondOwner"),
    changeSelectedFinancialOwner: createReducer("selectedFinancialOwner"),
    changeSelectedIsValid: createReducer("selectedIsValid"),
    changeSelectedComplexity: createReducer("selectedComplexity"),
    changeSelectedClassification: createReducer("selectedClassification"),
    changeSelectedTags: createReducer("selectedTags"),
    changeSelectedStatus: createReducer("selectedStatus"),
    changeSelectedGP2024Min: createReducer("selectedGP2024Min"),
    changeSelectedGP2024Max: createReducer("selectedGP2024Max"),
    changeSelectedGP2025Min: createReducer("selectedGP2025Min"),
    changeSelectedGP2025Max: createReducer("selectedGP2025Max"),
    changeSelectedGP2026Min: createReducer("selectedGP2026Min"),
    changeSelectedGP2026Max: createReducer("selectedGP2026Max"),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInitiativeOverviewData.pending, (state) => {
        state.overviewStatus = "loading";
        state.overviewTableData = [];
        state.financialTableData = [];
      })
      .addCase(fetchInitiativeOverviewData.rejected, (state) => {
        state.overviewStatus = "failed";
        state.overviewTableData = [];
        state.financialTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchInitiativeOverviewData.fulfilled, (state, action) => {
        state.overviewStatus = "succeeded";
        state.overviewTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      })
      .addCase(fetchInitiativeFinancialData.pending, (state) => {
        state.financialStatus = "loading";
        state.financialTableData = [];
        state.overviewTableData = [];
      })
      .addCase(fetchInitiativeFinancialData.rejected, (state) => {
        state.financialStatus = "failed";
        state.overviewTableData = [];
        state.financialTableData = [];
        state.overviewTableRowLength = 0;
      })
      .addCase(fetchInitiativeFinancialData.fulfilled, (state, action) => {
        state.financialStatus = "succeeded";
        state.financialTableData = action.payload.data;
        state.overviewTableRowLength = action.payload.totalCount;
      });
  },
});

export const {
  changeTab,
  changeSubtab,
  handleShowChart,
  handleSortModel,
  handlePageLimitChange,
  handleOverviewPageNo,
  changeCategory,
  changeCategorySector,
  changeSector,
  changeLever,
  changeDriver,
  changeIsAllSector,
  changeIsAllLever,
  changeIsAllDriver,
  handleOverviewPagination,
  handleChaangeTablePostData,
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGP2024Max,
  changeSelectedGP2024Min,
  changeSelectedGP2025Max,
  changeSelectedGP2025Min,
  changeSelectedGP2026Max,
  changeSelectedGP2026Min,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
} = InitiativeSlice.actions;

export default InitiativeSlice.reducer;
