import React, { useEffect } from "react";
import FilterComponent from "./components/FilterComponent";
import "../../styles/scorecard.style.scss";
import ScorecardPBI from "./ScorecardPBI";
import { useDispatch, useSelector } from "react-redux";
import { fetchScoreCardData } from "../../redux/reducers/ScoreCardSlice";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import PercentIcon from "@mui/icons-material/Percent";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const ScorecardMainPage = () => {
  const dispatch = useDispatch();
  const { scorecardData, scorecardStatus } = useSelector(
    (state) => state.ScoreCardSlice
  );

  useEffect(() => {
    let filters = {
      filters: {
        sector: [],
        market: [],
      },
    };
    dispatch(fetchScoreCardData(filters));
  }, [dispatch]);

  useEffect(() => {
    console.log("Scorecard Data from redux ::", scorecardData, scorecardStatus);
  }, [scorecardData, scorecardStatus]);

  const convertToMillion = (number) => {
    if (number) {
      return (
        (number / 100000).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
      );
    } else {
      return null;
    }
  };

  const breadcrumbs = [
    <Link to={"/"} underline="hover" key="1" color="inherit">
      Home
    </Link>,
    <Typography key="3" color="text.primary" fontSize={14}>
      {" "}
      Scorecard
    </Typography>,
  ];

  return (
    <div className="container-fullpage">
      <div className="sc-link">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ margin: "2px 0", fontSize: 14 }}
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div
        className="sc-link"
        style={{ margin: "2px 0", borderBottom: "1px solid #BFBFBF" }}
      />
      {/* Filter Section  */}
      <div>
        <FilterComponent />
      </div>

      {/* Card Section  */}
      {/* <div className="sc-card-section">
        {scorecardStatus === "loading" ? (
          <div
            style={{
              width: "100%",
              height: "130px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="sc-card">
              <div className="sc-card-title">Actual & Target GM%</div>
              <div className="sc-card-content">
                <div className="sc-card-bottom">
                  <div>
                    <div className="sc-card-title" style={{ fontSize: "12px" }}>
                      2023 actual GM%
                    </div>
                    <div className="sc-card-values">
                      {scorecardData?.current_gm}%
                    </div>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #bfbfbf",
                      height: "80px",
                      marginLeft: "25px",
                    }}
                  />
                  <div style={{ marginLeft: "25px" }}>
                    <div className="sc-card-title" style={{ fontSize: "12px" }}>
                      2027 target GM%
                    </div>
                    <div className="sc-card-values">
                      {scorecardData?.target_gm}%
                    </div>
                  </div>
                  <div className="sc-card-values">
                    {scorecardData?.current_gm}%
                  </div>
                  <div
                    style={{
                      background: "#dee7e7",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      width: "max-content",
                      fontSize: 14,
                      marginTop: "10px",
                    }}
                  >
                    2027 target{" "}
                    <span style={{ fontWeight: "800" }}>
                      {" "}
                      {scorecardData?.target_gm}%
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    background: "#3FBAD9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-20px",
                  }}
                >
                  <PercentIcon style={{ color: "#fff", fontSize: 30 }} />
                </div>
              </div>
              <div className="sc-card-title">2023 actual GM%</div>
              <div className="sc-card-content">
                <div className="">
                  <div className="sc-card-values">
                    {scorecardData?.current_gm}%
                  </div>
                  <div
                    style={{
                      background: "#dee7e7",
                      padding: "5px 10px",
                      borderRadius: "10px",
                      width: "max-content",
                      fontSize: 14,
                      marginTop: "10px",
                    }}
                  >
                    2027 target{" "}
                    <span style={{ fontWeight: "800" }}>
                      {" "}
                      {scorecardData?.target_gm}%
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    background: "#3FBAD9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-20px",
                  }}
                >
                  <PercentIcon style={{ color: "#fff", fontSize: 30 }} />
                </div>
              </div>
            </div>
            <div className="sc-card">
              <div className="sc-card-title">Original Gap% to Target</div>
              <div className="sc-card-content">
                <div className="sc-card-bottom">
                  <div>
                    <div className="sc-card-title">GM%</div>
                    <div className="sc-card-values">
                      {scorecardData?.gap_gm}%
                    </div>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #bfbfbf",
                      height: "80px",
                      marginLeft: "25px",
                    }}
                  />
                  <div style={{ marginLeft: "25px" }}>
                    <div className="sc-card-title">GP$</div>
                    <div className="sc-card-values">
                      ${convertToMillion(scorecardData?.gap_gp)}
                       {Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(scorecardData?.gap_gp)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    background: "#F98E2C",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-20px",
                  }}
                >
                  {" "}
                  <QueryStatsIcon style={{ color: "#fff", fontSize: 30 }} />
                </div>
              </div>
            </div>
            <div className="sc-card">
              <div className="sc-card-title">In Plan activities</div>
              <div className="sc-card-content">
                <div className="sc-card-bottom">
                  <div>
                    <div className="sc-card-title">GM%</div>
                    <div className="sc-card-values">
                      {scorecardData?.inv_gm}%
                    </div>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #bfbfbf",
                      height: "80px",
                      marginLeft: "25px",
                    }}
                  />
                  <div style={{ marginLeft: "25px" }}>
                    <div className="sc-card-title">GP$</div>
                    <div className="sc-card-values">
                      ${convertToMillion(scorecardData?.inv_gp)}
                      {Intl.NumberFormat("de-DE", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(scorecardData?.inv_gp)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    background: "#5DB134",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-20px",
                  }}
                >
                  <CalendarMonthIcon style={{ color: "#fff", fontSize: 30 }} />
                </div>
              </div>
            </div>
          </>
        )}
      </div> */}

      {/* PBI Section  */}
      <ScorecardPBI />
    </div>
  );
};

export default ScorecardMainPage;
