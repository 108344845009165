import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
  Popover,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPlaygroundGetHeadwindTailwindsData,
  fetchPlaygroundGetPlanConfidenceData,
  fetchPlaygroundGetTargetMarginData,
} from "../../../redux/reducers/PlaygroundSlice";

const CustomMultiSelect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  name,
  icon,
  isAllSelected,
  setIsAllSelected,
  currentTab
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const dispatch = useDispatch();
  const {
    tablePostData,
    overviewPaginationModel,
    sortModel,
    currentCategory,
    currentMarket,
    currentSector,
    isAllCategory,
    isAllMarket,
    isAllSector,
    // currentTab,
  } = useSelector((state) => state.PlaygroundSlice);
  
  // console.log(
  //   "data from redux :::",
  //   overviewPaginationModel,
  //   sortModel
  // );
  
  useEffect(() => {}, [
    dispatch,
    tablePostData,
    overviewPaginationModel,
    // currentTab,
    sortModel,
  ]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (option) => {
    let newSelectedOptions;
    if (option === "All") {
      if (isAllSelected) {
        newSelectedOptions = [];
        dispatch(setIsAllSelected(false));
      } else {
        newSelectedOptions = options;
        dispatch(setIsAllSelected(true));
      }
    } else {
      if (selectedOptions.includes(option)) {
        newSelectedOptions = selectedOptions.filter((item) => item !== option);
        dispatch(setIsAllSelected(false));
      } else if (isAllSelected) {
        newSelectedOptions = options.filter((item) => item !== option);
        dispatch(setIsAllSelected(false));
      } else {
        newSelectedOptions = [...selectedOptions, option];
        if (newSelectedOptions.length === options.length) {
          dispatch(setIsAllSelected(true));
        }
      }
    }
    // setSelectedOptions(newSelectedOptions);
    dispatch(setSelectedOptions(newSelectedOptions));
  };

  const handleApply = () => {
    let filterObj = {
      sector: !isAllSector ? currentSector.length && currentSector : [],
      market: !isAllMarket ? currentMarket.length && currentMarket : [],
      category: !isAllCategory ? currentCategory.length && currentCategory : [],
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      // sortBy: sortModel.length
      //   ? (sortModel[0]["field"] = "name" ? "inititative" : sortModel[0]?.["field"])
      //   : "gp_2024",
      // direction: sortModel.length ? sortModel[0]?.sort : "desc",
      sortBy: "gp_2024",
      direction: "desc",
      filters: filterObj,
      // filters: {
      //   [name.toLowerCase()]: selectedOptions,
      // },
    };

    // let unFilterData = {
    //   pageNo: 1,
    //   limit: overviewPaginationModel?.pageSize,
    //   sortBy: sortModel.length
    //     ? (sortModel[0].field = "name" ? "inititative" : sortModel[0]?.field)
    //     : "gp_2024",
    //   direction: sortModel.length ? sortModel[0]?.sort : "desc",
    // };

    // const postData = filterData;
    // const postData = isAllSelected ? unFilterData : filterData;
    if (currentTab == "target-margin") {
      dispatch(fetchPlaygroundGetTargetMarginData(filterData));
    }
    if (currentTab == "plan-confidence") {
      dispatch(fetchPlaygroundGetPlanConfidenceData(filterData));
    }

    if (currentTab == "headwind-tailwind") {
      dispatch(fetchPlaygroundGetHeadwindTailwindsData(filterData));
    }

    
    console.log(
      `Selected options for ${name} ::>>`,
      isAllSelected ? "all" : selectedOptions
    );
    handleClose();

    // dispatch(fetchInitiativeOverviewData());
  };

  const open = Boolean(anchorEl);

  useEffect(() => {}, [tablePostData]);

  const handleClearFilter = () => {
    let newSelectedOptions;
    if (isAllSelected) {
      newSelectedOptions = [];
      dispatch(setIsAllSelected(false));
    } else {
      newSelectedOptions = options;
      dispatch(setIsAllSelected(true));
    }
    dispatch(setSelectedOptions(newSelectedOptions));
    // handleClose();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={!anchorEl ? handleOpen : console.log("keep open")}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        {icon}
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "column" }}
        size="small"
      >
        <p style={{ margin: 0 }}>{name}</p>
        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          multiple
          value={isAllSelected ? "All" : selectedOptions}
          renderValue={(selected) => {
            // if (selectedOptions.length === options.length) {
            if (isAllSelected) {
              return "All";
            }
            // return selected.join(", ");
          }}
          // onClick={handleOpen}
          open={false} // Prevent default menu from opening
          sx={{
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              minWidth: "230px",
              maxWidth: "max-content",
              marginLeft: "-10px",
              marginTop: "5px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "300px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #dae8ff",
            },
          }}
        >
          <div>
            <MenuItem
              key="all"
              value="All"
              onClick={() => handleCheckboxChange("All")}
            >
              <Checkbox
                checked={isAllSelected}
                style={{ padding: 0, color: "#2280ef" }}
              />
              <ListItemText primary="All" />
            </MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                onClick={() => handleCheckboxChange(option)}
              >
                <Checkbox
                  checked={
                    isAllSelected ? true : selectedOptions.indexOf(option) > -1
                  }
                  style={{ padding: 0, color: "#2280ef" }}
                />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
            <div
              style={{
                display: "flex",
                padding: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleClearFilter}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Clear
              </button>
              <button
                onClick={handleApply}
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  background: "#2280EF",
                  color: "#fff",
                  border: "1px solid #2280EF",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default CustomMultiSelect;
