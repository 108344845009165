import React, { useEffect, useState } from "react";
import InterestsOutlinedIcon from "@mui/icons-material/InterestsOutlined";
import SingleSelect from "./SingleSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMarket,
  changeSector,
} from "../../../redux/reducers/ScoreCardSlice";
import axios from "axios";

const FilterComponent = () => {
  const [ABUlist, setABUlist] = useState([]);
  const [currentABU, setCurrentABU] = useState("All");
  const [sectorList, setSectorList] = useState([]);
  const [marketList, setMarketList] = useState([]);

  const { currentSector, currentMarket } = useSelector(
    (state) => state.ScoreCardSlice
  );

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(
      "Scorecard Current Sector and Market",
      currentSector,
      currentMarket
    );
  }, [dispatch, currentSector, currentMarket]);

  useEffect(() => {
    const fetchFiltersList = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters`
      );
      const data = await response.data.data;
      console.log("Filter Response Data :::", data);
      setSectorList(data.sector);
      setMarketList(data.market);
    };
    fetchFiltersList();
  }, []);

  return (
    <div className="init-filter-list">
      <div className="init-filter-item-disabled">
        <SingleSelect
          name={"Segment"}
          options={ABUlist}
          selectedOptions={currentABU}
          disabled={true}
          setSelectedOptions={setCurrentABU}
          //   isAllSelected={isAllSector}
          //   setIsAllSelected={changeIsAllSector}
          icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
        />
      </div>
      <div className="init-filter-item">
        <SingleSelect
          name={"Country"}
          options={marketList}
          selectedOptions={currentMarket}
          setSelectedOptions={changeMarket}
          //   isAllSelected={isAllSector}
          //   setIsAllSelected={changeIsAllSector}
          icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
        />
      </div>
      <div className="init-filter-item">
        <SingleSelect
          name={"Sector"}
          options={sectorList}
          selectedOptions={currentSector}
          setSelectedOptions={changeSector}
          //   isAllSelected={isAllSector}
          //   setIsAllSelected={changeIsAllSector}
          icon={<InterestsOutlinedIcon sx={{ fontSize: "18px" }} />}
        />
      </div>
    </div>
  );
};

export default FilterComponent;
