import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { useSelector } from "react-redux";
import axios from "axios";

const reportId = process.env.REACT_APP_FINANCIAL_VS_INITIATIVE_REPORT_ID;
const groupId = process.env.REACT_APP_FINANCIAL_VS_INITIATIVE_GROUP_ID;
const embedUrl =
  "https://app.powerbi.com/groups/c5b94f6b-789d-4428-8337-936384c7844c/reports/dce546a3-1f97-441f-9a0d-3840a3b7d40d/ReportSection?experience=power-bi";

const PBIReport = () => {
  // const { overviewTableData, financialTableData } = useSelector(
  //   (state) => state.InitiativeSlice
  // );

  const [PBItokens, setPBItokens] = useState({});
  const [filters, setFilters] = useState([]);

  //   useEffect(() => {
  //     const categorySector = {
  //       $schema: "http://powerbi.com/product/schema#basic",
  //       target: {
  //         table: "YourTableName",
  //         column: "YourColumnName",
  //       },
  //       operator: "In",
  //       values: ["Value1", "Value2"],
  //       filterType: models.FilterType.BasicFilter,
  //     };
  //     setFilters([categorySector]);
  //   }, []);

  useEffect(() => {
    const fetchPBIToken = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/power-bi/token`,
          {
            reportId: reportId,
            groupId: groupId,
          }
        );
        const data = await response.data.data;
        console.log("PBI token resp ::", data);
        setPBItokens(data);
      } catch (error) {
        console.error("Error while fetching PBI token...", error);
      }
    };

    fetchPBIToken();
  }, []);

  return (
    <div className="pbi-chart">
      <PowerBIEmbed
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
          id: reportId,
          embedUrl: PBItokens?.embedUrl,
          accessToken: PBItokens?.embedToken,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
          //   filters: filters,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
              pageNavigation: {
                visible: false, // Hides the navigation pane
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              function () {
                console.log("Report rendered");
              },
            ],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ],
            ["visualClicked", () => console.log("visual clicked")],
            ["pageChanged", (event) => console.log(event)],
          ])
        }
        cssClassName={"reportClass"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
    </div>
  );
};

export default PBIReport;
