import React, { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { DataGrid } from "@mui/x-data-grid";
import "../../../styles/initdetails.style.scss";
import axios from "axios";
import FilterComponent from "./FilterComponent";
import {
  fetchPlaygroundGetTargetMarginData,
} from "../../../redux/reducers/PlaygroundSlice";

const tableStyles = {
  "& .MuiDataGrid-cell": {
    justifyContent: "center",
  },
  "& .MuiDataGrid-columnHeader": {
    justifyContent: "center",
    padding: 0,
  },
  "& .MuiDataGrid-virtualScrollerContent": {
    background: "#fff",
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiDataGrid-container--top::after": {
    content: "none",
    display: "none",
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    boxShadow: "0px 4px 16px 0px #3241FF29",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0 0 0 0",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "none",
    background: "none",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334D74",
  },
  '& .MuiDataGrid-columnHeader[data-field="COUNTRY"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#3FBAD926",
  },

  '& .MuiDataGrid-columnHeader[data-field="SECTOR"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#3FBAD926",
  },

  '& .MuiDataGrid-columnHeader[data-field="CATEGORY"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#3FBAD926",
  },

  '& .MuiDataGrid-columnHeader[data-field="TARGET_2024"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#E4D4C799",
  },
  '& .MuiDataGrid-columnHeader[data-field="TARGET_2025"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#E4D4C799",
  },
  '& .MuiDataGrid-columnHeader[data-field="TARGET_2026"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#E4D4C799",
  },
  '& .MuiDataGrid-columnHeader[data-field="TARGET_2027"] .MuiDataGrid-columnHeaderTitleContainer':
  {
    backgroundColor: "#E4D4C799",
  },
};

const TargetMarginSetting = () => {
  const dispatch = useDispatch();

  const { id, SECTOR, driver, lever, year } = useParams();
  console.log("Params value :::", id, SECTOR);
  const history = useHistory();

  const {
    playgroundTableData,
  } = useSelector((state) => state.PlaygroundSlice);

  const [impactRows, setImpactRows] = useState([]);
  const [initiativeDetailsResp, setInitiativeDetailsResp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [targetMarginRows, setTargetMarginRows] = useState(true);
  
  const { pathname } = useLocation();
  const [totalCatPercentage, setTotalCatPercentage] = useState("");
  const [CATEGORYAllocationLoading, setCATEGORYAllocationLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateTotal = (data) => {
    let total = data.reduce(
      (acc, curr) => parseFloat(acc) + parseFloat(curr.allocation),
      0
    );
    return total;
  };


  const fetchCATEGORYAllocationDetails = async (postdata) => {
    setCATEGORYAllocationLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/initiative/CATEGORY-allocation`,
        { ...postdata }
      );

      const data = await response.data.data;
      console.log("CATEGORY allocation resp ::", data);
      setCATEGORYAllocationLoading(false);
      // let total = data.reduce(
      //   (acc, curr) => parseFloat(acc) + parseFloat(curr.allocation),
      //   0
      // );

      let total = calculateTotal(data);
      setTotalCatPercentage(total);
    } catch (error) {
      console.error("Error while fetching CATEGORY Allocation :::", error);
      setCATEGORYAllocationLoading(false);
    }
  };

  const fetchTargetMarginDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/playground/get-target-margin`
      );
      
      // Add an id to each row
      const targetMarginData = response.data.data.map((row, index) => ({
        ...row,
        id: index, 
        editable:true,
      }));
      
      // Save the rows in state
      setTargetMarginRows(targetMarginData);
  
      setLoading(false);
    } catch (error) {
      console.error("Error while fetching target margin details:", error);
      setLoading(false);
    }
  };
  
  useEffect(() => {
    dispatch(fetchPlaygroundGetTargetMarginData());
    // fetchTargetMarginDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const updateInitiativeDetails = async () => {
    handleClose();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/initiative/save`,
        {
          ...initiativeDetailsResp,
        }
      );
      const resp = await response.data;
      console.log("Update resp :::", resp);
      window.scrollTo(0, 0);
      fetchTargetMarginDetails();
      // alert("Saved data successfully !");
    } catch (error) {
      console.error("Error while saving initiative details ::", error);
      alert("Something went wrong, Please try again!");
    }
  };


  const TargetMarginColumns = [
    {
      field: 'COUNTRY',
      headerName: 'Country',
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: 600,
            color: '#334D74'
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: 'SECTOR',
      headerName: 'Sector',
      align: "center",
      flex: 1,
    },
    {
      field: 'CATEGORY',
      headerName: 'Category',
      align: "center",
      type: 'number',
      editable: true,
      flex: 1,
    },
    {
      field: 'TARGET_2024',
      headerName: 'GM Target 2024',
      align: "center",
      flex: 1,
      editable: true,
      width: 160,
      renderCell: (params) => (
        params.value >= 0 && (
          // (isNaN(params.value) ? (
          //   params.value
          // ) : (
          <div style={{ marginLeft: 30 }}>
            {params.value &&
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                // minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
            {params?.row.editable && (
              <span>
                <IconButton
                  onClick={() =>
                    params.api.startCellEditMode({
                      id: params.id,
                      field: "TARGET_2024",
                    })
                  }
                >
                  <EditNoteIcon />
                </IconButton>
              </span>
            )}
          </div>
        ))
    },
    {
      field: 'TARGET_2025',
      headerName: 'GM Target 2025',
      align: "center",
      editable: true,
      flex: 1,
      width: 160,
      renderCell: (params) => (
        params.value >= 0 && (
          // (isNaN(params.value) ? (
          //   params.value
          // ) : (
          <div style={{ marginLeft: 30 }}>
            {params.value &&
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                // minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
            {params?.row.editable && (
              <span>
                <IconButton
                  onClick={() =>
                    params.api.startCellEditMode({
                      id: params.id,
                      field: "TARGET_2025",
                    })
                  }
                >
                  <EditNoteIcon />
                </IconButton>
              </span>
            )}
          </div>
        ))
    },
    {
      field: 'TARGET_2026',
      headerName: 'GM Target 2026',
      align: "center",
      editable: true,
      flex: 1,
      sortable: false,
      width: 160,
      renderCell: (params) => (
        params.value >= 0 && (
          // (isNaN(params.value) ? (
          //   params.value
          // ) : (
          <div style={{ marginLeft: 30 }}>
            {params.value &&
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                // minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
            {params?.row.editable && (
              <span>
                <IconButton
                  onClick={() =>
                    params.api.startCellEditMode({
                      id: params.id,
                      field: "TARGET_2026",
                    })
                  }
                >
                  <EditNoteIcon />
                </IconButton>
              </span>
            )}
          </div>
        ))
    },
    {
      field: 'TARGET_2027',
      headerName: 'GM Target 2027',
      align: "center",
      editable: true,
      flex: 1,
      sortable: false,
      width: 160,
      renderCell: (params) => (
        params.value >= 0 && (
          // (isNaN(params.value) ? (
          //   params.value
          // ) : (
          <div style={{ marginLeft: 30 }}>
            {params.value &&
              Intl.NumberFormat("de-DE", {
                style: "decimal",
                // minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(params.value)}
            {params?.row.editable && (
              <span>
                <IconButton
                  onClick={() =>
                    params.api.startCellEditMode({
                      id: params.id,
                      field: "TARGET_2027",
                    })
                  }
                >
                  <EditNoteIcon />
                </IconButton>
              </span>
            )}
          </div>
        ))
    },
  ];



  const handleCellDoubleClick = (params, event) => {
    event.stopPropagation();
  };

  const compareObject = (obj1, obj2, commonFieldName) => {
    const result = {};
    if (
      obj1[commonFieldName] &&
      obj2[commonFieldName] &&
      obj1[commonFieldName] === obj2[commonFieldName]
    ) {
      result[commonFieldName] = obj1[commonFieldName];
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && key !== commonFieldName) {
        if (!obj1.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          // result[key] = obj2[key];
          result[`impact_${commonFieldName}_${key}`] = obj2[key];
        }
      }
    }
    return result;
  };


  const processRowUpdate = (newRow, oldRow) => {
    // console.log("OLD & NEW Row :::", newRow, oldRow)
    console.log("NEW Row :::", newRow);
    const updatedRows = impactRows.map((row) => {
      if (row.id === newRow.id) {
        const updated2024 =
          newRow?.["TARGET_2024"] === "" ? 0 : parseFloat(newRow?.["TARGET_2024"]);
        const updated2025 =
          newRow?.["TARGET_2025"] === "" ? 0 : parseFloat(newRow?.["TARGET_2025"]);
        const updated2026 =
          newRow?.["TARGET_2026"] === "" ? 0 : parseFloat(newRow?.["TARGET_2026"]);
        let updateTotal = updated2024 + updated2025 + updated2026;
        let updateRow = { ...newRow, Total: updateTotal };
        newRow = updateRow;
        console.log("NEW UPDATED ROW ::", updateRow);
        return updateRow;
      } else {
        return row;
      }
    });
    // alert("Update")
    setImpactRows(updatedRows);
    // console.log(
    //   "difference key an value :::",
    //   compareObject(oldRow, newRow, newRow.field)
    // );

    const updatedObj = compareObject(oldRow, newRow, newRow.field);
    const editedImpactValueObj = {
      ...initiativeDetailsResp,
      ...updatedObj,
    };

    console.log("Updated Values :::", editedImpactValueObj);

    setInitiativeDetailsResp(editedImpactValueObj);
    return newRow;
  };

  const handleIsCellEditable = (params) => {
    return params.colDef.editable && params.row.editable;
  };

  return (
    <>
      <div >
        <div>

          <div style={{ padding: "20px " }}>
            <div style={{ margin: '0px 0px 20px 0px' }}>
              <FilterComponent currentTab="target-margin" />
            </div>
            <DataGrid
              rows={playgroundTableData}
              columns={TargetMarginColumns}
              pageSize={5}
              loading={playgroundTableData?.length < 1}
              columnHeaderHeight={30}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              autoHeight
              hideFooter
              hideFooterSelectedRowCount
              disableColumnFilter
              disableColumnResize
              disableColumnSorting
              disableColumnMenu
              isCellEditable={handleIsCellEditable}
              onCellDoubleClick={handleCellDoubleClick}
              processRowUpdate={processRowUpdate}
              experimentalFeatures={{ newEditingApi: true }}
              sx={tableStyles}
            />
          </div>
        </div>


        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#fff",
                width: "164px",
                height: "50px",
                background: "#004DCD",
                border: "none",
                borderRadius: 10,
                cursor: "pointer",
              }}
              onClick={handleClickOpen}
            >
              Save
            </button>
            <button
              style={{
                fontSize: "20px",
                fontWeight: 600,
                width: "164px",
                height: "50px",
                background: "#fff",
                border: "1px solid black",
                borderRadius: 10,
                marginLeft: 35,
                cursor: "pointer",
              }}
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to save current changes ?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={updateInitiativeDetails}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TargetMarginSetting;