import React, { useEffect, useState } from "react";
import InititativeTable from "./InititativeTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSubtab,
  changeTab,
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
} from "../../redux/reducers/InitiativeSlice";
import { GenerateRowsColumn } from "../../hooks/GenerateRowsColumn";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "../../styles/tabs.style.scss";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const InitTabs = [
  { label: "Initiative Overview", value: "initiative-overview", row_length: 8 },
  { label: "Initiatives KPI's", value: "imm-financial", row_length: 8 },
  // { label: "Extended View", value: "extended-view", row_length: 0 },
];

const InitSubTabs = [
  { label: "IMM Impact vs Plan", value: "imm-impact-vs-plan" },
  { label: "IMM Impanct vs Latest QF", value: "imm-impact-vs-latest-qf" },
];

const InitiativeTabs = () => {
  const dispatch = useDispatch();

  const {
    currentTab,
    currentSubTab,
    overviewStatus,
    overviewTableRowLength,
    financialTableRowLength,
    overviewPageNo,
    pageLimit,
    financialStatus,
    sortModel,
    overviewPaginationModel,
    overviewTableData,
    financialTableData,
    currentSector,
    currentLever,
    currentDriver,
    isAllSector,
    isAllLever,
    isAllDriver,
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedFinancialOwner,
    selectedIsValid,
    selectedComplexity,
    selectedClassification,
    selectedTags,
    selectedStatus,
    selectedGP2024Min,
    selectedGP2024Max,
    selectedGP2025Min,
    selectedGP2025Max,
    selectedGP2026Min,
    selectedGP2026Max,
  } = useSelector((state) => state.InitiativeSlice);
  const [localCurrentTab, setLocalCurrentTab] = useState("");
  const { rows, columns } = GenerateRowsColumn(
    currentTab,
    sortModel,
    overviewPaginationModel
  );

  const [loadingDownload, setLoadingDownload] = useState(false);
  const handleTabChange = (tab) => {
    dispatch(changeTab(tab));
  };

  const handleSubtabChange = (subtab) => {
    dispatch(changeSubtab(subtab));
  };

  // console.log("Table Status :::", overviewStatus)

  useEffect(() => {
    let filterObj = {
      sector: !isAllSector ? currentSector.length && currentSector : [],
      lever: !isAllLever ? currentLever.length && currentLever : [],
      driver: !isAllDriver ? currentDriver.length && currentDriver : [],
      gp_2024_min: selectedGP2024Min > 0 ? selectedGP2024Min : null,
      gp_2024_max: selectedGP2024Max > 0 ? selectedGP2024Max : null,
      gp_2025_min: selectedGP2025Min > 0 ? selectedGP2025Min : null,
      gp_2025_max: selectedGP2025Max > 0 ? selectedGP2025Max : null,
      gp_2026_min: selectedGP2026Min > 0 ? selectedGP2026Min : null,
      gp_2026_max: selectedGP2026Max > 0 ? selectedGP2026Max : null,
      initiative: selectedInitiative,
      owner: selectedOwner,
      delegate: selectedSecondOwner,
      financial_owner: selectedFinancialOwner,
      status: selectedStatus,
      complexity: selectedComplexity,
      // is_valid: selectedIsValid,
    };

    let postData = {
      pageNo: overviewPaginationModel.page + 1,
      limit: overviewPaginationModel.pageSize,
      sortBy: sortModel.length
        ? sortModel[0]?.field == "name"
          ? "initiative"
          : sortModel[0]?.field
        : "gp_2024",
      direction: sortModel.length ? sortModel[0]?.sort : "desc",
      filters: filterObj,
    };

    setLocalCurrentTab(currentTab);
    if (currentTab === "initiative-overview") {
      dispatch(fetchInitiativeOverviewData(postData));
    }
    if (currentTab === "imm-financial") {
      dispatch(fetchInitiativeFinancialData(postData));
    }
  }, [
    currentTab,
    dispatch,
    sortModel,
    overviewPaginationModel,
    // selectedInitiative,
    // selectedOwner,
    // selectedSecondOwner,
    // selectedFinancialOwner,
    // selectedIsValid,
    // selectedComplexity,
    // selectedClassification,
    // selectedTags,
    // selectedStatus,
    // selectedGP2024Min,
    // selectedGP2024Max,
    // selectedGP2025Min,
    // selectedGP2025Max,
    // selectedGP2026Min,
    // selectedGP2026Max,
  ]);

  const downloadTableData = async () => {
    setLoadingDownload(true);
    let apiURL =
      currentTab === "initiative-overview"
        ? `${process.env.REACT_APP_API_BASE_URL}/api/home/initiative-overview/export`
        : `${process.env.REACT_APP_API_BASE_URL}/api/home/imm-financial/export`;
    try {
      const response = await axios.post(apiURL, {
        limit: overviewPaginationModel.pageSize,
        sortBy: sortModel.length
          ? sortModel[0]?.field == "name"
            ? "initiative"
            : sortModel[0]?.field
          : "gp_2024",
        direction: sortModel.length ? sortModel[0]?.sort : "desc",
        filters: {
          sector: isAllSector ? [] : currentSector,
          lever: isAllLever ? [] : currentLever,
          driver: isAllDriver ? [] : currentDriver,
          gp_2024_min: selectedGP2024Min > 0 ? selectedGP2024Min : null,
          gp_2024_max: selectedGP2024Max > 0 ? selectedGP2024Max : null,
          gp_2025_min: selectedGP2025Min > 0 ? selectedGP2025Min : null,
          gp_2025_max: selectedGP2025Max > 0 ? selectedGP2025Max : null,
          gp_2026_min: selectedGP2026Min > 0 ? selectedGP2026Min : null,
          gp_2026_max: selectedGP2026Max > 0 ? selectedGP2026Max : null,
          initiative: selectedInitiative,
          owner: selectedOwner,
          delegate: selectedSecondOwner,
          financial_owner: selectedFinancialOwner,
          status: selectedStatus,
          complexity: selectedComplexity,
          // is_valid: selectedIsValid,
        },
      });
      const data = await response.data;
      const filename =
        currentTab == "initiative-overview"
          ? `Initiative Overview_${new Date()}`
          : `Initiative KPI's_${new Date()}`;
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
      setLoadingDownload(false);
      console.log("PBI token resp ::", data);
    } catch (error) {
      setLoadingDownload(false);
      console.error("Error while fetching PBI token...", error);
    }
  };

  return (
    <>
      <div className="initiative">
        <div className="init-tabs-list">
          {InitTabs.map((tab, index) => (
            <div
              key={index}
              className={
                localCurrentTab === tab.value ? "init-tab-active" : "init-tab"
              }
              onClick={() => handleTabChange(tab.value)}
              style={{ cursor: "pointer" }}
            >
              <p className="init-tabname">{tab.label}</p>
              {tab.row_length ? (
                <p className="init-tabrows-length">{overviewTableRowLength}</p>
              ) : null}
            </div>
          ))}
        </div>
        <button className="download-table" onClick={downloadTableData}>
          Download
          {loadingDownload && <CircularProgress size={20} />}
          <span className="download-icon">
            <FileDownloadOutlinedIcon fontSize="small" />
          </span>
        </button>
      </div>
      <div className="init-views">
        {localCurrentTab === "initiative-overview" && (
          <div className="init-table">
            <InititativeTable
              rows={overviewTableData}
              columns={columns}
              status={overviewStatus}
              rowCount={overviewTableRowLength}
            />
          </div>
        )}
        {localCurrentTab === "imm-financial" && (
          <div className="init-table">
            {/* <InitiativeFinancialTable rows={rows} columns={columns} /> */}
            <InititativeTable
              rows={financialTableData}
              columns={columns}
              columnGroup={"financial"}
              status={financialStatus}
              rowCount={overviewTableRowLength}
            />
          </div>
        )}
        {localCurrentTab === "extended-view" && (
          <div className="init-subtab-list ">
            {InitSubTabs.map((subtab) => (
              <div
                className={
                  currentSubTab === subtab.value
                    ? "init-subtab-active"
                    : "init-subtab"
                }
                onClick={() => handleSubtabChange(subtab.value)}
              >
                {subtab.label}
              </div>
            ))}
            <div className="init-table">
              {/* <InititativeTable
                rows={rows}
                columns={columns}
                columnGroup={"extended"}
              /> */}
              {/* <InitiativeExtendedTable
                rows={rows}
                columns={columns}
                // columnGroup={"extended"}
              /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InitiativeTabs;
