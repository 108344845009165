import React from "react";
import CategoryFilter from "../initiative/components/CategoryFilter";
import PBIChartArea from "../initiative/PBIChartArea";

const MarginWalkPage = () => {
  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gridColumn: "span 12",
        }}
      >
        <div>
          <div style={{ fontSize: 16, fontWeight: 600 }}>
            Margin Walk Future Years
          </div>
          <div style={{ fontSize: 14, fontWeight: 400, color: "#6B6B6B" }}>
            Take a look at the overall status
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <CategoryFilter />
        </div>
      </div>
      <PBIChartArea />
    </div>
  );
};

export default MarginWalkPage;
