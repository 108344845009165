import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

import {
  changeSelectedClassification,
  changeSelectedComplexity,
  changeSelectedFinancialOwner,
  changeSelectedGP2024Max,
  changeSelectedGP2024Min,
  changeSelectedGP2025Max,
  changeSelectedGP2025Min,
  changeSelectedGP2026Max,
  changeSelectedGP2026Min,
  changeSelectedInitiative,
  changeSelectedIsValid,
  changeSelectedOwner,
  changeSelectedSecondOwner,
  changeSelectedStatus,
  changeSelectedTags,
  fetchInitiativeFinancialData,
  fetchInitiativeOverviewData,
} from "../../../redux/reducers/InitiativeSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const InitiativeList = [
  "Inititative 1",
  "Inititative 2",
  "Inititative 3",
  "Inititative 4",
  "Inititative 5",
];
const TagList = ["Tag1", "Tag2", "Tag3", "Tag4", "Tag5"];
const StatusList = [
  "Not Started",
  "On track",
  "Off track",
  "Completed",
  "Cancelled",
];

const OwnerList = ["Srikant", "Hitesh", "Rudra", "Adnan"];
const ComplexityList = ["Low", "Medium", "High"];
const ClassificationList = [
  "For Discussion",
  "Convert into initiative",
  "Finalized",
  "Invalid",
];

const MoreFilterComponent = ({ name, icon }) => {
  // const [selectedInitiatives, setSelectedInititatives] = useState([]);
  // const [selectedTags, setSelectedTags] = useState([]);
  // const [selectedOwner, setSelectedOwner] = useState([]);
  // const [selectedSecondOwner, setSelectedSecondOwner] = useState([]);
  // const [selectedFinancialOwner, setSelectedFinancialOwner] = useState([]);
  // const [selectedStatus, setSelectedStatus] = useState([
  //   "Not Started",
  //   "On track",
  //   "Off track",
  // ]);
  // const [isValid, setIsValid] = useState("yes");
  // const [complexity, setComplexity] = useState("");
  // const [classification, setClassification] = useState("");
  // const [gp_2024_min, setgp_2024_min] = useState();
  // const [gp_2024_max, setgp_2024_max] = useState();
  // const [gp_2025_min, setgp_2025_min] = useState();
  // const [gp_2025_max, setgp_2025_max] = useState();
  // const [gp_2026_min, setgp_2026_min] = useState();
  // const [gp_2026_max, setgp_2026_max] = useState();

  const [searchOwner, setSearchOwner] = useState("");
  const [searchSecondOwner, setSearchSecondOwner] = useState("");
  const [searchFinancialOwner, setSearchFinancialOwner] = useState("");
  const [searchInitiative, setSearchInitiative] = useState(""); // Add this for initiative search

  const dispatch = useDispatch();
  const {
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedFinancialOwner,
    selectedIsValid,
    selectedComplexity,
    selectedClassification,
    selectedTags,
    selectedStatus,
    selectedGP2024Min,
    selectedGP2024Max,
    selectedGP2025Min,
    selectedGP2025Max,
    selectedGP2026Min,
    selectedGP2026Max,
    isAllDriver,
    isAllLever,
    isAllSector,
    currentDriver,
    currentLever,
    currentSector,
    currentTab,
    overviewPaginationModel,
  } = useSelector((state) => state.InitiativeSlice);

  const [moreFilterData, setMoreFilterData] = useState([]);

  useEffect(() => {
    let filterObj = {
      sector: !isAllSector ? currentSector.length && currentSector : [],
      lever: !isAllLever ? currentLever.length && currentLever : [],
      driver: !isAllDriver ? currentDriver.length && currentDriver : [],
    };

    const fetchMoreFilter = async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/filters/more`,
        { filters: filterObj }
      );
      const data = await response.data.data;

      console.log("Reponse for morefilter ::", data);
      setMoreFilterData(data);
    };

    fetchMoreFilter();
  }, [
    isAllDriver,
    isAllLever,
    isAllSector,
    currentDriver,
    currentLever,
    currentSector,
  ]);

  useEffect(() => {
    console.log(
      "More Filter Data from Redux ::",
      selectedStatus,
      selectedGP2024Min,
      selectedIsValid
    );
  }, [
    dispatch,
    selectedInitiative,
    selectedOwner,
    selectedSecondOwner,
    selectedFinancialOwner,
    selectedIsValid,
    selectedComplexity,
    selectedClassification,
    selectedTags,
    selectedStatus,
    selectedGP2024Min,
    selectedGP2024Max,
    selectedGP2025Min,
    selectedGP2025Max,
    selectedGP2026Min,
    selectedGP2026Max,
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = () => {
    dispatch(changeSelectedGP2024Min(0));
    dispatch(changeSelectedGP2024Max(0));
    dispatch(changeSelectedGP2025Min(0));
    dispatch(changeSelectedGP2025Max(0));
    dispatch(changeSelectedGP2026Min(0));
    dispatch(changeSelectedGP2026Max(0));
    dispatch(changeSelectedInitiative([]));
    dispatch(changeSelectedOwner([]));
    dispatch(changeSelectedSecondOwner([]));
    dispatch(changeSelectedFinancialOwner([]));
    dispatch(changeSelectedTags([]));
    dispatch(changeSelectedStatus([]));
    // dispatch(changeSelectedStatus(["NOT_STARTED", "ON_TRACK", "OFF_TRACK"]));
    dispatch(changeSelectedIsValid("yes"));
    dispatch(changeSelectedComplexity(""));
    dispatch(changeSelectedClassification(""));
  };

  const handleApply = () => {
    let filterObj = {
      sector: !isAllSector ? currentSector.length && currentSector : [],
      lever: !isAllLever ? currentLever.length && currentLever : [],
      driver: !isAllDriver ? currentDriver.length && currentDriver : [],
      gp_2024_min: selectedGP2024Min > 0 ? selectedGP2024Min : null,
      gp_2024_max: selectedGP2024Max > 0 ? selectedGP2024Max : null,
      gp_2025_min: selectedGP2025Min > 0 ? selectedGP2025Min : null,
      gp_2025_max: selectedGP2025Max > 0 ? selectedGP2025Max : null,
      gp_2026_min: selectedGP2026Min > 0 ? selectedGP2026Min : null,
      gp_2026_max: selectedGP2026Max > 0 ? selectedGP2026Max : null,
      initiative: selectedInitiative,
      owner: selectedOwner,
      delegate: selectedSecondOwner,
      financial_owner: selectedFinancialOwner,
      status: selectedStatus,
      complexity: selectedComplexity,
      // is_valid: selectedIsValid,

      // classifiaction: selectedClassification,
    };

    let filterData = {
      pageNo: 1,
      limit: overviewPaginationModel?.pageSize,
      sortBy: "gp_2024",
      direction: "desc",
      filters: filterObj,
    };
    if (currentTab == "initiative-overview") {
      dispatch(fetchInitiativeOverviewData(filterData));
    }
    if (currentTab == "imm-financial") {
      dispatch(fetchInitiativeFinancialData(filterData));
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  const handleInitCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedInitiative?.includes(option)) {
      newSelectedOptions = selectedInitiative.filter((item) => item !== option);
      // setSelectedInititatives(newSelectedOptions);
      dispatch(changeSelectedInitiative(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedInitiative, option];
      // setSelectedInititatives(newSelectedOptions);
      dispatch(changeSelectedInitiative(newSelectedOptions));
    }
  };

  const handleTagCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedTags?.includes(option)) {
      newSelectedOptions = selectedTags.filter((item) => item !== option);
      dispatch(changeSelectedTags(newSelectedOptions));
      // setSelectedTags(newSelectedOptions);
    } else {
      newSelectedOptions = [...selectedTags, option];
      dispatch(changeSelectedTags(newSelectedOptions));
      // setSelectedTags(newSelectedOptions);
    }
  };

  const handleOwnerCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedOwner?.includes(option)) {
      newSelectedOptions = selectedOwner.filter((item) => item !== option);
      // setSelectedOwner(newSelectedOptions);
      dispatch(changeSelectedOwner(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedOwner, option];
      dispatch(changeSelectedOwner(newSelectedOptions));
      // setSelectedOwner(newSelectedOptions);
    }
  };
  const handleSecondOwnerCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedSecondOwner?.includes(option)) {
      newSelectedOptions = selectedSecondOwner.filter(
        (item) => item !== option
      );
      // setSelectedOwner(newSelectedOptions);
      dispatch(changeSelectedSecondOwner(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedSecondOwner, option];
      dispatch(changeSelectedSecondOwner(newSelectedOptions));
      // setSelectedOwner(newSelectedOptions);
    }
  };
  const handleFinancialOwnerCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedFinancialOwner?.includes(option)) {
      newSelectedOptions = selectedFinancialOwner.filter(
        (item) => item !== option
      );
      // setSelectedOwner(newSelectedOptions);
      dispatch(changeSelectedFinancialOwner(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedFinancialOwner, option];
      dispatch(changeSelectedFinancialOwner(newSelectedOptions));
      // setSelectedOwner(newSelectedOptions);
    }
  };

  const handleStatusCheckbox = (option) => {
    let newSelectedOptions;
    if (selectedStatus?.includes(option)) {
      newSelectedOptions = selectedStatus.filter((item) => item !== option);
      // setSelectedStatus(newSelectedOptions);
      dispatch(changeSelectedStatus(newSelectedOptions));
    } else {
      newSelectedOptions = [...selectedStatus, option];
      dispatch(changeSelectedStatus(newSelectedOptions));
      // setSelectedStatus(newSelectedOptions);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        marginTop: 5,
      }}
      onClick={!anchorEl ? handleOpen : console.log("keep open")}
    >
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "50%",
          height: "28px",
          minWidth: "28px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "5px",
        }}
      >
        {icon}
        {/* <icon sx={{ fontSize: "16px !important" }} /> */}
      </div>
      <FormControl
        sx={{ minWidth: 150, display: "flex", flexDirection: "row" }}
        size="small"
      >
        <p style={{ margin: 0, fontSize: 14, fontWeight: 400, width: "100%" }}>
          {name}
        </p>
        <Select
          labelId="custom-multi-select-label"
          id="custom-multi-select"
          variant="standard"
          renderValue={(selected) => {
            // return "All";
          }}
          open={false}
          sx={{
            width: "100%",
            "& .MuiInput-underline:before": {
              borderBottom: "none",
            },
            "& .MuiSelect-standard:after": {
              borderBottom: "none",
            },
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              width: "320px",
              marginLeft: "-10px",
              marginTop: "15px",
              padding: "10px",
              borderRadius: "12px",
              maxHeight: "450px",
              overflowY: "auto",
              boxShadow: "0px 2px 5px 0px #dae8ff",
            },
          }}
        >
          <div>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                ID and Name
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Initiative"
                  value={searchInitiative}
                  onChange={(e) => setSearchInitiative(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                {moreFilterData?.initiative
                  ?.filter((init) =>
                    init
                      ?.toLowerCase()
                      ?.includes(searchInitiative?.toLowerCase())
                  )
                  ?.map((inititative) => (
                    <MenuItem
                      key={inititative}
                      value={inititative}
                      className="multi-select-checkbox-list"
                      onClick={() => handleInitCheckbox(inititative)}
                    >
                      <Checkbox
                        checked={selectedInitiative?.includes(inititative)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={inititative} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Owner
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Owner"
                  value={searchOwner}
                  onChange={(e) => setSearchOwner(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                {moreFilterData?.owner
                  ?.filter((name) =>
                    name?.toLowerCase()?.includes(searchOwner?.toLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multi-select-checkbox-list"
                      onClick={() => handleOwnerCheckbox(name)}
                    >
                      <Checkbox
                        checked={selectedOwner?.includes(name)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Second Owner
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Second Owner"
                  value={searchSecondOwner}
                  onChange={(e) => setSearchSecondOwner(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                {moreFilterData?.delegate
                  ?.filter((name) =>
                    name
                      .toLowerCase()
                      ?.includes(searchSecondOwner?.toLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multi-select-checkbox-list"
                      onClick={() => handleSecondOwnerCheckbox(name)}
                    >
                      <Checkbox
                        checked={selectedSecondOwner?.includes(name)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Financial Owner
              </AccordionSummary>
              <div>
                <input
                  placeholder="Search Financial Owner"
                  value={searchFinancialOwner}
                  onChange={(e) => setSearchFinancialOwner(e.target.value)}
                  style={{
                    padding: "10px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    marginBottom: "5px",
                  }}
                />
              </div>
              <AccordionDetails>
                {moreFilterData?.financial_owner
                  ?.filter((name) =>
                    name
                      ?.toLowerCase()
                      ?.includes(searchFinancialOwner?.toLocaleLowerCase())
                  )
                  ?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      className="multi-select-checkbox-list"
                      onClick={() => handleFinancialOwnerCheckbox(name)}
                    >
                      <Checkbox
                        checked={selectedFinancialOwner?.includes(name)}
                        style={{ padding: "0px", color: "#2280ef" }}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Is Valid
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="End"
                    value={selectedIsValid}
                    onChange={(e) =>
                      dispatch(changeSelectedIsValid(e.target.value))
                    }
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio />}
                      label={"Yes"}
                    />
                    <FormControlLabel
                      value={"no"}
                      control={<Radio />}
                      label={"No"}
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Complexity
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="End"
                    value={selectedComplexity}
                    onChange={(e) =>
                      dispatch(changeSelectedComplexity(e.target.value))
                    }
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {moreFilterData?.complexity?.map((complex, index) => (
                      <FormControlLabel
                        key={index}
                        value={complex}
                        control={<Radio />}
                        label={complex}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
            {/* <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Classification
              </AccordionSummary>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="End"
                    value={selectedClassification}
                    onChange={(e) =>
                      dispatch(changeSelectedClassification(e.target.value))
                    }
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {ClassificationList.map((cl) => (
                      <FormControlLabel
                        value={cl}
                        control={<Radio />}
                        label={cl}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion> */}
            {/* <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Tags
              </AccordionSummary>
              <AccordionDetails>
                {TagList.map((tag) => (
                  <MenuItem
                    key={tag}
                    value={tag}
                    className="multi-select-checkbox-list"
                    onClick={() => handleTagCheckbox(tag)}
                  >
                    <Checkbox
                      checked={selectedTags?.includes(tag)}
                      style={{ padding: "0px", color: "#2280ef" }}
                    />
                    <ListItemText primary={tag} />
                  </MenuItem>
                ))}
              </AccordionDetails>
            </Accordion> */}
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Status
              </AccordionSummary>
              <AccordionDetails>
                {/* <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-form-control-label-placement"
                    name="position"
                    defaultValue="End"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    {StatusList.map((status) => (
                      <FormControlLabel
                        value={status}
                        control={<Radio />}
                        label={status}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}
                {moreFilterData?.status?.map((status) => (
                  <MenuItem
                    key={status}
                    value={status}
                    className="multi-select-checkbox-list"
                    onClick={() => handleStatusCheckbox(status)}
                  >
                    <Checkbox
                      checked={selectedStatus?.includes(status)}
                      style={{ padding: "0px", color: "#2280ef" }}
                    />
                    <ListItemText primary={status.replaceAll("_", " ")} />
                  </MenuItem>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                GP 2024
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor="gp-2024-min"
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Min
                    </label>
                    <br />
                    <input
                      type="number"
                      id="gp-2024-min"
                      placeholder="enter value"
                      value={selectedGP2024Min}
                      onChange={(e) =>
                        dispatch(changeSelectedGP2024Min(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor="gp-2024-max"
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Max
                    </label>
                    <br />
                    <input
                      type="number"
                      id="gp-2024-max"
                      placeholder="enter value"
                      value={selectedGP2024Max}
                      onChange={(e) =>
                        dispatch(changeSelectedGP2024Max(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                GP 2025
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor="gp-2025-min"
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Min
                    </label>
                    <br />
                    <input
                      type="number"
                      id="gp-2025-min"
                      placeholder="enter value"
                      value={selectedGP2025Min}
                      onChange={(e) =>
                        dispatch(changeSelectedGP2025Min(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor="gp-2025-max"
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Max
                    </label>
                    <br />
                    <input
                      type="number"
                      id="gp-2025-max"
                      placeholder="enter value"
                      value={selectedGP2025Max}
                      onChange={(e) =>
                        dispatch(changeSelectedGP2025Max(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: "none", boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                GP 2026
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor="gp-2026-min"
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Min
                    </label>
                    <br />
                    <input
                      type="number"
                      id="gp-2026-min"
                      placeholder="enter value"
                      value={selectedGP2026Min}
                      onChange={(e) =>
                        dispatch(changeSelectedGP2026Min(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                  <div style={{ width: "48%" }}>
                    <label
                      htmlFor="gp-2026-max"
                      style={{ fontSize: "14px", color: "#b8b8b8" }}
                    >
                      Max
                    </label>
                    <br />
                    <input
                      type="number"
                      id="gp-2026-max"
                      placeholder="enter value"
                      value={selectedGP2026Max}
                      onChange={(e) =>
                        dispatch(changeSelectedGP2026Max(e.target.value))
                      }
                      style={{
                        width: "95%",
                        height: "30px",
                        marginRight: "5px",
                      }}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div
              style={{
                display: "flex",
                padding: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={handleClear}
                fullWidth
                variant="outlined"
                color="primary"
                style={{
                  background: "none",
                  border: "1px solid GrayText",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  color: "GrayText",
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Clear
              </button>
              <button
                onClick={handleApply}
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  background: "#2280EF",
                  color: "#fff",
                  border: "1px solid #2280EF",
                  borderRadius: 5,
                  padding: "5px 10px",
                  margin: "0 5px",
                  fontSize: 14,
                  cursor: "pointer",
                  width: "45%",
                }}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </Popover>
      </FormControl>
    </div>
  );
};

export default MoreFilterComponent;
