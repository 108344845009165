import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

// const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#FF007F",
  "#8CFF00",
  "#00FFC5",
  "#FF6F00",
  "#C700FF",
  "#FFD300",
  "#00FFB3",
  "#FF006E",
  "#00E4FF",
  "#FF00A2",
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  data,
}) => {
  const x = cx + (outerRadius + 20) * Math.cos(-midAngle * RADIAN);
  const y = cy + (outerRadius + 20) * Math.sin(-midAngle * RADIAN);
  // const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  // const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // const y = cy + radius * Math.sin(-midAngle * RADIAN);
  //   console.log("Chart data receiving in props :::", data);

  return (
    <text
      x={x}
      y={y}
      fill="#000"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={15}
      overflow={"auto"}
    >
      {data[index]["allocation"] > 0 &&
        `${data[index]["category"]} ${(
          Math.round(data[index]["allocation"] * 100) / 100
        ).toFixed(0)}%`}
    </text>
  );
};

const CategoryAllocationChart = ({ data }) => {
    // console.log("Chart data receiving in props :::", data);

  const numbericData = data.map((d) => ({
    ...d,
    allocation: parseFloat(d.allocation),
  }));
  return (
    <PieChart width={800} height={500}>
      <Pie
        data={numbericData}
        cx={350}
        cy={250}
        labelLine={true}
        // label={renderCustomizedLabel}
        label={(props) =>
          renderCustomizedLabel({ ...props, data: numbericData })
        }
        outerRadius={130}
        fill="#8884d8"
        dataKey="allocation"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CategoryAllocationChart;
